import React, { useState, useEffect } from 'react';
import useTokenValidation from '../../useTokenValidation';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme, Link, Box, Typography, Table, TableHead, TableBody, TableRow, TableCell, Pagination } from '@mui/material';
import DeleteIcon from '../../images/icons/delete_icon.svg';
import GetPDF from './getPDFSavedProperties';
import BreadCrumbs from '../breadCrumbs';

const PropertiesArchive = ({ setIsAuthenticated }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [savedProperties, setSavedProperties] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useTokenValidation(setIsAuthenticated);

    useEffect(() => {
        getSavedProperties(pageNo);
    }, [pageNo]);

    const getSavedProperties = async (page) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}user/saved-properties/?number_per_page=10&page=${page}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });
            setSavedProperties(response.data.saved_properties);
            setTotalPages(response.data.total_number_of_pages);
        } catch (error) {
            console.log(error);
        }
    };

    const deleteProperty = async (building_id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}user/saved-properties/?building_id=${building_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                }
            });
            
            if (response.status === 200) {
                // Return to previous page if last item on the page has been deleted
                if (savedProperties.length <= 1 && pageNo > 1) {
                    setPageNo(totalPages-1);
                    getSavedProperties(pageNo);
                }else{
                    getSavedProperties(pageNo);
            }}
        } catch (error) {
            console.log(error);
        }
    };

    const handlePageChange = (event, value) => {
        setPageNo(value);
    };

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: '#FFFFFF',
                flexGrow: 1,
                height: '100%',
                overflow: 'auto',
                mt: 6
            }}
        >
            <Box sx={{ mt: 1 }}>
                <BreadCrumbs pageNames={[{name:'properties_archive.my_properties', route:'/properties'}]} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant='h2' sx={{ mt: 1, mb: 2 }}>{t('properties_archive.properties_archive')}</Typography>
                <Box sx={{ display: 'flex', mt:2}}>
                    <Pagination
                        count={totalPages}
                        page={pageNo}
                        onChange={handlePageChange}
                        color="primary"
                        variant="outlined"
                    />
                </Box>
                </Box>
                <Table>
                    <TableHead >
                        <TableRow sx={{ backgroundColor: '#F9FAFB' }}>
                            <TableCell sx={{ width: '65%' }}>
                                {t('properties_archive.properties')}
                            </TableCell>
                            <TableCell>{t('main_page.report')}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {savedProperties.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    {t('properties_archive.no_saved_properties')}
                                </TableCell>
                            </TableRow>
                        ) : (
                            savedProperties.map((property, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        sx={{
                                            textOverflow: 'ellipsis',
                                            marginRight: 0,
                                            paddingRight: 0
                                        }}
                                    >
                                        <Link href={`/prevent/adaptions/${property.building_id}?risk_year=${property.risk_year}&return_period=${property.return_period}&has_basement=${property.has_basement}&is_ground_floor=${property.is_ground_floor}&experienced_sewage_backup=${property.experienced_sewage_backup}`} color='#000000' underline="hover">
                                            <Typography variant="solutionTitle">
                                                {property.property_address}
                                            </Typography>
                                        </Link>
                                    </TableCell>
                                    <TableCell><GetPDF property={property} /></TableCell>
                                    <TableCell>
                                        <img src={DeleteIcon} alt='delete-icon' style={{ width: 20, height: 20, cursor: 'pointer' }} onClick={() => deleteProperty(property.building_id)} />
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>

            </Box>
        </Box>
    );
}

export default PropertiesArchive;
