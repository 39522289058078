import { Checkbox, Accordion, styled } from '@mui/material';

export const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'transparent',
        '& .MuiSvgIcon-root': {
            borderColor: '#F2708E', // Change border color on hover
        },
      },
    '& .MuiSvgIcon-root': {
        color: 'transparent',
        border: '1px solid #E9EBEE',
        borderRadius: '3px',
        boxShadow: 'none',
        width: '20px',
        height: '20px',
      },
      '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
            color: '#D3385C',
            border: 0,
            boxShadow: 'none',
        },
      },
    }));

export const CustomAccordion = styled(Accordion)(({ theme, index, array }) => ({
    marginBottom: '10px',
    boxShadow: 'none', 
    '&:before': {
        display: 'none',
    },
    '&.Mui-expanded': {
        margin: 0,
    },
    '&.MuiPaper-root': {
        borderRadius: 0,
    },
    '& .MuiAccordionSummary-root': {
        borderRadius: '5px',
        backgroundColor: '#F8F6F4',
        minHeight: '48px', 
        padding: '20px',
        margin: 0,
        width: '100%',
        '&:hover': {
            backgroundColor: '#F2EEE9',
        },
        '&.Mui-expanded': {
            minHeight: '48px',
            margin: 0, 
        },
    },
    '& .MuiAccordionSummary-content': {
        margin: 0, 
        '&.Mui-expanded': {
            margin: 0,
        },
    },
    '& .MuiAccordionDetails-root': {
        padding: '20px',
        borderRadius: '5px',
        border: '1px solid #EEE9E1',
        marginTop: '10px',
        marginBottom: '10px',
    },
}));

