// Function to format the cobenefits level
export const formatCobenefits = (cobenefits) => {
    if (cobenefits <= 1)  {
        return 1;
    }else if (cobenefits <= 2) {
        return 2;
    }else{
        return 3;
    }
};

// Function to get the background color based on risk level
export const getRiskColor = (riskLevel) => {
    switch (riskLevel) {
        case "very high": return "#D22B25"; // very high
        case "high": return "#FFB649"; // high
        case "medium": return "#FFDE69"; // medium
        case "low": return "#0685CD"; // low
        case "no risk": return "#59A678"; // no risk
        default: return "#000000"; // unknown
    }
};

// Function to get the text color based on risk level
export const getRiskTextColor = (riskLevel) => {
    switch (riskLevel) {
        case "very high": return "#D22B25"; // very high
        case "high": return "#E57C00"; // high
        case "medium": return "#A0770F"; // medium
        case "low": return "#0685CD"; // low
        case "no risk": return "#59A678"; // no risk
        default: return "#000000"; // unknown
    }
};

export const formatAccuracy = (accuracy) => {
    switch (accuracy) {
        case "high": return "A"; 
        case "medium": return "B";
        case "low": return "C"; 
        default: return "Unknown"; // unknown
    }
};

export const getRiskLevelText = (risk, t) => {
    switch (risk) {
        case "very high":
            return t("main_page.very_high");
        case "high":
            return t("main_page.high");
        case "medium":
            return t("main_page.medium");
        case "low":
            return t("main_page.low");
        case "no risk":
            return t("main_page.no_risk");
        default:
            return "Unknown";
    }
};

export const getByProfessionalText = (answer, t) => {
    switch (answer) {
        case "yes":
            return t("main_page.yes");
        case "no":
            return t("main_page.no");
        case "idk":
            return t("main_page.i_don_t_know");
        default:
            return "Unknown";
    }
};
