import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import Topbar from './topbar';
import Sidebar from './sidebar';
import { MobileTopbar, MobileSidebar } from './mobileNav';

export default function GlobalNav({ isAuthenticated, setIsAuthenticated }) {
  const isSmallerScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleOutsideClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [open]);

  return (
    <>
      {isSmallerScreen ? (
        <MobileTopbar toggleDrawer={toggleDrawer} setIsAuthenticated={setIsAuthenticated} />
      ) : (
        <Topbar open={open} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
      )}
      {isSmallerScreen ? (
        open && <MobileSidebar ref={sidebarRef} open={open} toggleDrawer={toggleDrawer} />
      ) : (
        <Sidebar open={open} toggleDrawer={toggleDrawer} />
      )}
    </>
  );
}
