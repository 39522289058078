import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, FormControlLabel, Typography, TextField, InputLabel, useMediaQuery, useTheme, IconButton, Divider, InputAdornment, Avatar } from '@mui/material'
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import axios from 'axios';
import { Close } from '@mui/icons-material'
import { ReactComponent as LoaderButton } from '../../../images/loaders/loader_white.svg';
import FormCalender from './formCalender';
import SuccessfulTick from '../../../images/icons/successful_tick_icon.svg';
import DeleteIcon from '../../../images/icons/delete_icon.svg';
import DeleteConfirmation from './deleteConfirmation';
import { CustomCheckbox } from '../../customStyledComponents';

const AddMeasure = ({ buildingId, riskSolutions, setExistingMeasures, setAddMeasure, selectedMeasure, selectedMeasures, setSelectedMeasures, newMeasures, setNewMeasures }) => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [measureSize, setMeasureSize] = useState(null);
    const [measureCost, setMeasureCost] = useState(null);
    const [installationDate, setInstallationDate] = useState(null);
    const [lastMaintenanceDate, setLastMaintenanceDate] = useState(null);
    const [installedByProfessional, setInstalledByProfessional] = useState({
        yes: false,
        no: false,
        idk: false,
    });
    const [errors, setErrors] = useState({});
    const [installationAnchorEl, setInstallationAnchorEl] = useState(null);
    const [maintenanceAnchorEl, setMaintenanceAnchorEl] = useState(null);
    const openInstallationCalendar = Boolean(installationAnchorEl);
    const openMaintenanceCalendar = Boolean(maintenanceAnchorEl);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [measureToDelete, setMeasureToDelete] = useState(null);

    const handleOpenDeleteDialog = () => {
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setMeasureToDelete(null);
    };

    const handleConfirmDelete = () => {
        // Remove the selected measure from the list of selected measures
        const updatedSelectedMeasures = selectedMeasures.filter(measure => measure !== selectedMeasure);
        setSelectedMeasures(updatedSelectedMeasures);
    
        // Remove the measure from newMeasures if it exists there
        const updatedNewMeasures = newMeasures.filter(measure => measure !== selectedMeasure);
        setNewMeasures(updatedNewMeasures);
    
        // Close the delete dialog
        setDeleteDialogOpen(false);
    
        // Check if all remaining selected measures are in the newMeasures list
        const allMeasuresAdded = updatedSelectedMeasures.every(measure => updatedNewMeasures.includes(measure));
    
        // Close the AddMeasure component if there are no more selected measures to add
        if (allMeasuresAdded) {
            setAddMeasure(false);
        }
    };    

    const handleOpenInstallationCalendar = (event) => {
        setInstallationAnchorEl(event.currentTarget);
    };

    const handleCloseInstallationCalendar = () => {
        setInstallationAnchorEl(null);
    };

    const handleOpenMaintenanceCalendar = (event) => {
        setMaintenanceAnchorEl(event.currentTarget);
    };

    const handleCloseMaintenanceCalendar = () => {
        setMaintenanceAnchorEl(null);
    };

    const handleInstallationDateChange = (newDate) => {
        setInstallationDate(newDate);
        handleCloseInstallationCalendar();
    };

    const handleMaintenanceDateChange = (newDate) => {
        setLastMaintenanceDate(newDate);
        handleCloseMaintenanceCalendar();
    };

    // Validation logic for measureSize and measureCost
    const validateInputs = () => {
        const newErrors = {};
        const sizePattern = /^[0-9]+$/; // Only positive whole numbers

        // Allow null or empty values for measureSize and measureCost
        if (measureSize && !sizePattern.test(measureSize)) {
            newErrors.measureSize = t('adaptions_page.must_be_whole_number');
        }
        if (measureCost && !sizePattern.test(measureCost)) {
            newErrors.measureCost = t('adaptions_page.must_be_whole_number');
        }

        // Check if any professional installation option is selected
        if (!(installedByProfessional.yes || installedByProfessional.no || installedByProfessional.idk)) {
            newErrors.installedByProfessional = t('adaptions_page.installed_by_professional_required');
        }

        setErrors(newErrors);

        // Return whether the form has errors
        return Object.keys(newErrors).length === 0;
    };

    const getExistingMeasures =  async () => {
        try {
            await axios.get(`${process.env.REACT_APP_API_BASE_URL}user/existing-measures/?building_id=${buildingId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                setExistingMeasures(response.data);
            })}
        catch (error) {
            console.log(error);
        }
        }

        const addAMeasure = async () => {
            if (!validateInputs()) {
                return; // Do not proceed if there are validation errors
            }

            setIsLoading(true);

            const formattedInstallationDate = installationDate ? dayjs(installationDate).format('YYYY-MM-DD') : null;
            const formattedMaintenanceDate = lastMaintenanceDate ? dayjs(lastMaintenanceDate).format('YYYY-MM-DD') : null;
            const byProfessional = Object.keys(installedByProfessional).find(key => installedByProfessional[key]);
        
            try {
                await axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}user/existing-measures/?building_id=${buildingId}&by_professional=${byProfessional}&measure_name=${selectedMeasure}&measure_size=${measureSize}&measure_cost=${measureCost}&installation_date=${formattedInstallationDate}&maintenance_date=${formattedMaintenanceDate}`,
                    {},
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );                
                getExistingMeasures();

                // Add the current measure to newMeasures
                setNewMeasures((prev) => {
                    const updatedNewMeasures = [...prev, selectedMeasure];
                    
                    // Check if all selected measures are in newMeasures after the update
                    const allMeasuresAdded = selectedMeasures.every(measure => updatedNewMeasures.includes(measure));
                    if (allMeasuresAdded) {
                        setAddMeasure(false); // Close the component if all measures have been added
                    }
                    
                    return updatedNewMeasures;
                });

                setMeasureSize(null);
                setMeasureCost(null);
                setInstallationDate(null);
                setLastMaintenanceDate(null);
                setInstalledByProfessional({
                    yes: false,
                    no: false,
                    idk: false,
                });
                setIsLoading(false);
                setNewMeasures((prev) => [...prev, selectedMeasure]);

            } catch (error) {
                console.log(error);
                setIsLoading(false);
                if (error.response.status === 401) {
                    navigate('/login');
                }
            }
        };        

    const confirmInstalledByProfessional = (event) => {
        setInstalledByProfessional({
            ...installedByProfessional,
            [event.target.name]: event.target.checked,
        });
    };

    const getImageSrc = (measure) => {
        try {
            // Attempt to require the specific image
            return require(`../../../images/solutions/${measure}.jpg`);
        } catch (error) {
            // If the image doesn't exist, return the default "coming soon" image
            return require(`../../../images/solutions/image_coming_soon.jpg`);
        }
    };

    return (
        <Box>
            {selectedMeasures[0] === selectedMeasure && (
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mb: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h3">{t('adaptions_page.report_new_measure')}</Typography>
                    <IconButton onClick={() => {setAddMeasure(false)}}>
                        <Close />
                    </IconButton>
                </Box>
            )}

            {selectedMeasures.map((measure, index) => {
                if (measure !== selectedMeasure) return null;

                return (
                    <Box key={index}>
                        <Typography variant="subtitle3" sx={{ mb: 2 }}>
                            {t('adaptions_page.measure')}: {t(`solution.${measure}.name`)}
                        </Typography>

                        {/* Conditionally render success message if the measure is added */}
                        {newMeasures.includes(measure) && (
                            <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor: 'white', padding: 1, my: 3, alignItems: 'center', borderRadius: '5px' }}>
                                <Avatar sx={{ width: 30, height: 30, mx: 1 }} src={getImageSrc(measure)} />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="h5">{t(`solution.${measure}.name`)}</Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                                        <Typography variant="addressPaperChecks" sx={{ color: '#0F914B' }}>{t('adaptions_page.added_correctly')}</Typography>
                                        <img src={SuccessfulTick} alt='successful-tick' style={{ width: 20, height: 20 }} />
                                    </Box>
                                </Box>
                            </Box>

                        )}
                    </Box>
                );
            })}

            {/* Measure form fields */}
            {!newMeasures.includes(selectedMeasure) && (
                <>
                    <Box>
                        <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2, mb: 1 }}>
                            {t('adaptions_page.name_of_measure')}
                        </InputLabel>
                        <TextField
                            margin="none"
                            color="primary"
                            fullWidth
                            id="nameOfMeasure"
                            name="nameOfMeasure"
                            value={t(`solution.${selectedMeasure}.name`)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Avatar sx={{ width: 24, height: 24 }} src={getImageSrc(selectedMeasure)} />
                                    </InputAdornment>
                                ),
                                style: { fontSize: '14px' },
                                readOnly: true
                            }}
                        />
                    </Box>

                    {/* Additional fields for measure size, cost, etc. */}
                    <Box sx={{ display: 'inline-flex', gap: 2 }}>
                            <Box sx={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2, mb:1 }}>{t('adaptions_page.measure_size')}</InputLabel>
                                <TextField
                                    margin="none"
                                    color="primary"
                                    fullWidth
                                    id="size"
                                    placeholder={t('adaptions_page.enter_size')}
                                    name="size"
                                    inputProps={{style: {fontSize: '14px'}}}
                                    value={measureSize}
                                    onChange={(e) => setMeasureSize(e.target.value)}
                                    error={Boolean(errors.measureSize)}
                                    helperText={errors.measureSize}
                                    FormHelperTextProps={{sx: { color: 'red' }}}
                                />
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column', width: '25%'}}>
                                <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2, mb:1 }}>{t('pdf.table_header_units')}</InputLabel>
                                <TextField
                                    disabled
                                    margin="none"
                                    color="primary"
                                    fullWidth
                                    id="size"
                                    name="size"
                                    inputProps={{style: {fontSize: '14px'}}}
                                    sx={{
                                        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                                            border: '0.5px solid var(--Grey-Scale-Colors-Grey-Stronger, #E9EBEE)',
                                        }
                                    }} // Override the default disabled border
                                    value={t(`solution.${selectedMeasure}.unit`)}
                                />
                            </Box>
                        </Box>

                        <Box sx={{display: 'inline-flex', gap: 2}}>
                            <Box sx={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2, mb: 1 }}>{t('adaptions_page.measure_cost')}</InputLabel>
                                <TextField
                                    margin="none"
                                    color="primary"
                                    fullWidth
                                    id="cost"
                                    name="cost"
                                    placeholder={t('adaptions_page.enter_cost')}
                                    inputProps={{style: {fontSize: '14px'}}}
                                    value={measureCost}
                                    onChange={(e) => setMeasureCost(e.target.value)}
                                    error={Boolean(errors.measureCost)}
                                    helperText={errors.measureCost}
                                    FormHelperTextProps={{sx: { color: 'red' }}}
                                />
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column', width: '25%'}}>
                                <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2, mb:1 }}>{t('adaptions_page.currency')}</InputLabel>
                                <TextField
                                    disabled
                                    margin="none"
                                    color="primary"
                                    fullWidth
                                    id="size"
                                    name="size"
                                    inputProps={{style: {fontSize: '14px'}}}
                                    sx={{
                                        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                                            border: '0.5px solid var(--Grey-Scale-Colors-Grey-Stronger, #E9EBEE)',
                                        }
                                    }} // Override the default disabled border
                                    value={riskSolutions[Object.keys(riskSolutions)[0]].currency}
                                />
                            </Box>
                        </Box>

                        <FormCalender
                            label={t('adaptions_page.installation_date')}
                            dateValue={installationDate}
                            onDateChange={handleInstallationDateChange}
                            open={openInstallationCalendar}
                            anchorEl={installationAnchorEl}
                            onOpen={handleOpenInstallationCalendar}
                            onClose={handleCloseInstallationCalendar}
                            installationDate={installationDate}
                        />

                        <FormCalender
                            label={t('adaptions_page.last_maintenance')}
                            dateValue={lastMaintenanceDate}
                            onDateChange={handleMaintenanceDateChange}
                            open={openMaintenanceCalendar}
                            anchorEl={maintenanceAnchorEl}
                            onOpen={handleOpenMaintenanceCalendar}
                            onClose={handleCloseMaintenanceCalendar}
                            installationDate={installationDate}
                        />

                        <Box sx={{my:2}}>
                        <Box sx={{display: 'inline-flex', animation: errors.installedByProfessional ? 'shake 0.5s ease-in-out' : 'none', color: errors.installedByProfessional ? 'primary.main' : 'inherit'}}>
                        <Typography variant="body1" color="info" sx={{ mb:1, fontSize: isSmallerScreen ? '0.75rem' : '14px' }}>
                        {t('adaptions_page.installed_by_professional')}
                        </Typography>
                        {errors.installedByProfessional && <Typography color="primary" variant="body2" sx={{ ml: 1 }}>*</Typography>}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <FormControlLabel
                                label={t('main_page.yes')}
                                name='yes'
                                control={<CustomCheckbox checked={installedByProfessional.yes} onChange={confirmInstalledByProfessional} disabled={installedByProfessional.no || installedByProfessional.idk} />}
                                sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '14px' } }}
                            />
                            <FormControlLabel
                                label={t('main_page.no')}
                                name='no'
                                control={<CustomCheckbox checked={installedByProfessional.no} onChange={confirmInstalledByProfessional} disabled={installedByProfessional.yes || installedByProfessional.idk} />}
                                sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '14px' } }}
                            />
                            <FormControlLabel
                                label={t('main_page.i_don_t_know')}
                                name='idk'
                                control={<CustomCheckbox checked={installedByProfessional.idk} onChange={confirmInstalledByProfessional} disabled={installedByProfessional.yes || installedByProfessional.no} />}
                                sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '14px' } }}
                            />
                        </Box>
                    </Box>
    
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            variant="contained"
                            sx={{padding: '5px 6px', mr: 1}}
                            onClick={addAMeasure}
                        >
                            {!isLoading ? `+ ${t('adaptions_page.save_measure')}` : <LoaderButton className="pdf-spinner" />}
                        </Button>
                        <Button onClick={handleOpenDeleteDialog}>
                            <img src={DeleteIcon} alt='delete-icon' style={{ width: 20, height: 20, cursor: 'pointer', marginRight: 1 }} />
                            {t('adaptions_page.delete')}
                        </Button>
                    </Box>
    
                    {/* Delete confirmation dialog */}
                    <DeleteConfirmation 
                        open={isDeleteDialogOpen} 
                        onClose={handleCloseDeleteDialog} 
                        onConfirm={handleConfirmDelete} 
                    />
    
                    <Divider sx={{ my: 4 }} />
                </>
            )}
        </Box>
    );
    
}

export default AddMeasure;