import React from 'react';
import { useSearchContext } from '../../SearchContext';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, Select, OutlinedInput, MenuItem, ListItemText } from "@mui/material";
import InfoIcon from '../../images/icons/info_icon.svg';
import Typography from '@mui/material/Typography';
import { useRef, useState } from 'react';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';

const returnPeriodOptions = [
    20,
    50,
    100,
];

const ReturnPeriodFilter = () => {
    const { returnPeriod, setReturnPeriod } = useSearchContext();
    const { t } = useTranslation();
    const [popperContent, setPopperContent] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);

    const handleReturnPeriodChange = (event) => {
        setReturnPeriod(event.target.value);
    };
    const handleMouseEnter = (event, content) => {
        setAnchorEl(event.currentTarget);
        setPopperContent(content);
    };

    const handleMouseLeave = () => {
        setAnchorEl(null);
        setPopperContent('');
    };
    return (
        <Box
            sx={{
                position: 'absolute',
                top: 9,
                left: 105,
                zIndex: 1000,
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <FormControl sx={{ width: 'auto', height: 45 }}>
                <Select
                    displayEmpty
                    value={returnPeriod}
                    onChange={handleReturnPeriodChange}
                    renderValue={(selected) => {
                        if (selected) {
                            return `${returnPeriod} ${t('adaptions_page.years')} ${t('adaptions_page.return_period')}`;
                        }
                    }}
                    sx={{
                        backgroundColor: 'white',
                        width: 'auto',
                        padding: '10 15px',
                        height: 45,
                        fontSize: '12px'
                    }}
                >
                    {returnPeriodOptions.map((option, index) => (
                        <MenuItem
                            key={index}
                            value={option}
                            disabled={option !== 20}
                            sx={{ fontSize: '12px', py: 1, px: 1 }}
                        >
                            {`${option} ${t('adaptions_page.years')} ${t('adaptions_page.return_period')}`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {/* <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center', // Center align the image vertically
                }}
            >
                <img
                    src={InfoIcon}
                    alt="info-icon"
                    style={{
                        cursor: 'pointer',
                        marginLeft: 3,
                        height: 17,
                        width: 17,
                    }}
                    onMouseEnter={(e) => handleMouseEnter(e, t('adaptions_page.return_period'))}
                    onMouseLeave={handleMouseLeave}
                />
            </Box>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="right" disablePortal>
                <Paper sx={{ padding: 1, maxWidth: 175, wordWrap: 'break-word', boxShadow: '0px 0px 23px 0px rgba(0, 0, 0, 0.10)' }}>
                    <Typography variant='poppers'>{popperContent}</Typography>
                </Paper>
            </Popper> */}
        </Box>
    );
}

export default ReturnPeriodFilter;
