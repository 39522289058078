import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Avatar, IconButton } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function UserDropdown({setIsAuthenticated}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem('user_data'));
    const { t } = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNavigate = (path) => {
        setAnchorEl(null);
        navigate(path);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        localStorage.removeItem("token");
        setIsAuthenticated(false);
        localStorage.removeItem('previousSearchlat');
        localStorage.removeItem('previousSearchlng');
        localStorage.removeItem('user_data');
        navigate("/login");
    };

    return (
        <>
            <IconButton onClick={handleClick}>
            {userData ? 
                <Avatar sx={{ width: 32, height: 32 }}>{userData.first_name[0].toUpperCase()}</Avatar>
                :
                <Avatar sx={{ width: 32, height: 32 }}></Avatar>
            }
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={() => handleNavigate("/user")}>{t('navigation.profile')}</MenuItem>
                <MenuItem onClick={() => handleNavigate("/properties")}>{t('navigation.my_properties')}</MenuItem>
                <MenuItem onClick={handleLogout}>{t('navigation.logout')}</MenuItem>
            </Menu>
        </>
    );
}