import React, { useEffect } from 'react';
import { useSearchContext } from '../../SearchContext';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import GoogleMap from './googleMap';
import useTokenValidation from '../../useTokenValidation';
import { useMediaQuery, useTheme } from '@mui/material';
import AlertIcon from '../../images/icons/alert_icon.svg';
import BreadCrumbs from '../breadCrumbs';

const MapDash = ({ setIsAuthenticated }) => {
    const { setSearchLat, setSearchLong, setSelectedBuilding, setHasLoadedData, addressNotFound, setAddressNotFound } = useSearchContext()
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useTokenValidation(setIsAuthenticated);

    useEffect(() => {
        setHasLoadedData(false)
        setSelectedBuilding(null)
        setSearchLat(null)
        setSearchLong(null)
    },[])

    const alertBox = (
        <Box
        sx={{
            position: 'absolute',
            top: isSmallerScreen ? '10%' : '50%',
            left: isSmallerScreen ? '50%' : '70%',
            transform: isSmallerScreen ? 'translateX(-50%)' : 'translate(-50%, -50%)',
            zIndex: 10000,
            width: isSmallerScreen ? '90%' : '35%',
            height: isSmallerScreen ? '90%' : '20%',
            borderRadius: '8px',
            textAlign: 'center'
        }}
    >
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '-30px',
            }}>
            <img src={AlertIcon} alt="Alert Icon" style={{width: '50px', height: '50px'}} />
        </Box>
        <Alert 
            icon={false}
            onClose={() => setAddressNotFound(false)}
            sx={{
                backgroundColor: '#fff',
                padding: 2,
                paddingTop: 3,
                fontSize: isSmallerScreen ? '10px' : '14px',
            }}
        >
        <AlertTitle sx={{fontSize: isSmallerScreen ? '10px' : '14px'}}>Property information for the requested address is not yet in the database.</AlertTitle>
        If you think it should be in the database, please contact <strong>webapp-support@mitigrate.com.</strong>
        </Alert>
    </Box>
    )

    return (
        <>
        { isSmallerScreen ? (
            <Box sx={{mt:7}}>
                <BreadCrumbs pageNames={[{name:'main_page.prevent_map', route:'/prevent'}]} />
                <Box sx={{display: 'flex', position: 'relative', height: isExtraSmallScreen ? '82vh' : '58vh', borderRadius: '10px', overflow: 'hidden', mt:1}}>
                    <GoogleMap setIsAuthenticated={setIsAuthenticated}/>
                </Box>
            </Box>
        ) : (
            <Box sx={{mt:7}}>
                <BreadCrumbs pageNames={[{name:'main_page.prevent_map', route:'/prevent'}]} />
            <Box
            sx={{
                display: 'flex',
                position: 'relative',
                ml: 3,
                mr: 3,
                mt: 1,
                borderRadius: '10px',
                height: '85vh',
                overflow: 'auto',
            }}
            >
        {addressNotFound && alertBox
        }
                <GoogleMap setIsAuthenticated={setIsAuthenticated}/>
            </Box>
            </Box>
            )
        }
        </>
    );
}

export default MapDash;