import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { useSearchContext } from "../../SearchContext";
import axios from 'axios';
import { InputLabel, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as LoaderButton } from '../../images/loaders/loader_white.svg';

const ChangePassword = () => {
    const { t } = useTranslation();
    const { changeError, setChangeError } = useSearchContext();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [changeSuccessful, setChangeSuccessful] = useState("");
    const token = localStorage.getItem('token');
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleChangePassword = async (event) => {
        event.preventDefault();
        setChangeSuccessful("");
        setChangeError("");

        if (!newPassword || !confirmPassword) {
            setChangeError("Please enter all fields.");
            return;
        }

        try {
            setIsLoading(true);
            const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}user/update/password/authorized/`, {
                new_password: newPassword,
                confirm_new_password: confirmPassword,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setIsLoading(false);
                setChangeSuccessful("Password changed successfully.");
            }
        } catch (error) {
            setIsLoading(false);
            if (error.response) {
                setChangeError(`${error.response.data.error}`);
            } else if (error.request) {
                console.error("Request error:", error.request);
                setChangeError("Could not connect to the server. Please try again later.");
            } else {
                console.error("Error:", error.message);
                setChangeError("An error occurred. Please try again later.");
            }
        }
    };

    return (
        <Box component="form" onSubmit={handleChangePassword}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                <Box>
                    <Typography variant="h2" sx={{ mt: 2, mb: 2 }}>{t('login.update_password')}</Typography>
                    <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>{t('login.update_password_text')}</Typography>
                </Box>
                {!isSmallerScreen && (
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2, padding: '10px', borderRadius: '3px' }}
                        onClick={handleChangePassword}
                    >
                        {isLoading ? <LoaderButton className='spinner' /> : t('login.submit')}
                    </Button>
                )}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isSmallerScreen ? 'column' : 'row',
                    justifyContent: 'space-between',
                    mb: 2,
                }}
            >
                <Box sx={{ width: isSmallerScreen ? '100%' : '48%', mb: isSmallerScreen ? 2 : 0 }}>
                    <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F' }}>{t('login.new_password')}</InputLabel>
                    <TextField
                        margin="none"
                        color="primary"
                        fullWidth
                        name="new_password"
                        type="password"
                        id="new_password"
                        placeholder={t('login.enter_new_password')}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </Box>
                <Box sx={{ width: isSmallerScreen ? '100%' : '48%' }}>
                    <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F' }}>{t('login.confirm_new_password')}</InputLabel>
                    <TextField
                        margin="none"
                        color="primary"
                        fullWidth
                        name="confirm_new_password"
                        type="password"
                        id="confirm_new_password"
                        placeholder={t('login.confirm_new_password')}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </Box>
            </Box>
            {isSmallerScreen && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 3 }}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ padding: '10px', borderRadius: '3px' }}
                        onClick={handleChangePassword}
                    >
                        {isLoading ? <LoaderButton className='spinner' /> : t('login.submit')}
                    </Button>
                </Box>
            )}
            {changeError && (
                <Typography color="error" sx={{ mt: 2 }}>
                    {changeError}
                </Typography>
            )}
            {changeSuccessful && (
                <Typography color="green" sx={{ mt: 2 }}>
                    {changeSuccessful}
                </Typography>
            )}
        </Box>
    );
};

export default ChangePassword;
