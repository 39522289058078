import React from "react";
import { Box, Button } from '@mui/material';
import full_screen_icon from "../../images/icons/full_screen_icon.svg";
import full_screen_icon_hover from "../../images/icons/full_screen_icon_hover.svg";

    export const ZoomControl = ({ map, isSmallerScreen }) => {

        const handleZoomIn = () => {
            map.setZoom(map.getZoom() + 1);
        };
        
        const handleZoomOut = () => {
            map.setZoom(map.getZoom() - 1);
        };
        
        return (
            <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                bottom: isSmallerScreen ? 'unset' : 1, // Unset the bottom positioning if screen is small
                top: isSmallerScreen ? 40 : 'unset', // Position it at the top right under fullscreen control if small screen
                right: isSmallerScreen ? 2.5 : 0.5,
                padding: isSmallerScreen ? 1.5 : 3,
                zIndex: 100,
            }}
            >
            <Button
                sx={{
                color: '#8A8A8A',
                fontSize: '20px',
                fontWeight: '600',
                backgroundColor: 'white',
                width: '35px',
                minWidth: 5,
                marginBottom: '4px',
                '&:hover': { backgroundColor: 'white', color: '#D3385C' },
                }}
                onClick={handleZoomIn}
            >
                +
            </Button>
            <Button
                sx={{
                color: '#8A8A8A',
                fontSize: '20px',
                fontWeight: '600',
                backgroundColor: 'white',
                width: '35px',
                minWidth: 5,
                '&:hover': { backgroundColor: 'white', color: '#D3385C' },
                }}
                onClick={handleZoomOut}
            >
                -
            </Button>
            </Box>
        );
    };
    
    export const FullscreenControl = ({ map, selectedBuilding, isSmallerScreen }) => {

        const handleToggleFullscreen = () => {
            if (document.fullscreenElement) {
            document.exitFullscreen();
            } else {
            map.getDiv().requestFullscreen();
            }
        };
        
        return (
            <Box
            sx={{
                position: 'absolute',
                top: 5,
                right: 1,
                padding: isSmallerScreen || selectedBuilding ? 0 : 1,
                zIndex: 100,
            }}
            >
            <Button sx={{ '&:hover': { backgroundColor: 'transparent' } }} onClick={handleToggleFullscreen}>
                <img src={full_screen_icon} alt="full screen icon" />
            </Button>
            </Box>
        );
    };
    
    export const MapTypeControl = ({ map, selectedBuilding, isSmallerScreen, mapTypeText, setMapTypeText }) => {

        const handleToggleMapType = () => {
            const currentType = map.getMapTypeId();
            const newType = currentType === 'roadmap' ? 'satellite' : 'roadmap';
            map.setMapTypeId(newType);
            setMapTypeText(newType === 'roadmap' ? 'Satellite' : 'Roadmap');
        };
        
        return (
            <Box
            sx={{
                position: 'absolute',
                bottom: isSmallerScreen ? 'unset' : 1, // Unset the bottom positioning if screen is small
                left: isSmallerScreen ? 1 : 'unset', // Position it at the top left if small screen
                top: isSmallerScreen || selectedBuilding ? 5 : 'unset', // Add top positioning if screen is small
                paddingTop: isSmallerScreen || selectedBuilding ? 0.5 : 'unset',
                paddingBottom: isSmallerScreen || selectedBuilding ? 'unset': 3,
                paddingLeft: isSmallerScreen || selectedBuilding ? 1.5 : 3,
                zIndex: 100,
            }}
            >
            <Button
                sx={{
                color: '#8A8A8A',
                backgroundColor: 'white',
                padding: '8px',
                minWidth: 0,
                '&:hover': { backgroundColor: 'white', color: '#D3385C' },
                }}
                onClick={handleToggleMapType}
            >
                {mapTypeText}
            </Button>
            </Box>
        );
    };
    