import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/login/login";
import ChangePassword from "./components/login/ChangePassword";
import ForgotPassword from "./components/login/forgotPassword";
import ResetPassword from "./components/login/resetPassword";
import GlobalNav from "./components/navbar/globalNav";
import { CssBaseline, Box } from "@mui/material";
import Adaptions from "./components/adaptions/adaptions";
import MapDash from "./components/map/mapDash";
import OnlineReport from "./components/adaptions/report/onlineReport";
import UnderConstruction from "./components/underConstruction";
import UserProfile from "./components/userProfile/userProfile";
import PropertiesArchive from "./components/savedProperties/propertiesArchive";
import UnderMaintenance from "./components/underMaintenance";
import i18n from "./i18n/config";
import axios from "axios";

const PrivateRoute = ({ element, isAuthenticated }) => {
  return isAuthenticated ? element : <Navigate to="/login" replace />;
};

function App() {
  const storedToken = localStorage.getItem("token");
  const [isAuthenticated, setIsAuthenticated] = useState(!!storedToken);
  const [underMaintenance, setUnderMaintenance] = useState(false);

  // getting solutions translation and updating "solution" key in the i18n object
  const getSolutionsTranslations = async () => {
    // loop over the languages and get the translations for each language
    const languages = ['en', 'no'];
    for (let i = 0; i < languages.length; i++) {
      axios.get(`${process.env.REACT_APP_API_BASE_URL}info/translations/?language=${languages[i]}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storedToken}`
        }
    }).then((response) => {
      
      const data = response.data;
      i18n.addResourceBundle(languages[i], "translations", { solution: data.solution });

    }).catch((error) => {}
    );
  }
  }
  
  useEffect(() => {
    {isAuthenticated && getSolutionsTranslations()}
    i18n.changeLanguage(localStorage.getItem('language') || 'en');
  }
  , [isAuthenticated]);

  return (
    <BrowserRouter>
      {underMaintenance ? (
        <Box data-cy={'undermaintenance'}>
          <UnderMaintenance />
        </Box>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          {isAuthenticated && <GlobalNav isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}/>}
          <Box
            component="main"
            sx={{
              backgroundColor: '#FFFFFF',
              flexGrow: 1,
              height: '100%',
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            <Routes>
              {/* Authenticated routes */}
              <Route path="/dashboard" element={<PrivateRoute element={<UnderConstruction pageName="Dashboard" />} isAuthenticated={isAuthenticated} />} />
              <Route path="/prevent" element={<PrivateRoute element={<MapDash setIsAuthenticated={setIsAuthenticated} />} isAuthenticated={isAuthenticated} />} />
              <Route path="prevent/adaptions/:building_id" element={<PrivateRoute element={<Adaptions setIsAuthenticated={setIsAuthenticated} />} isAuthenticated={isAuthenticated} />} />
              <Route path="/view-report" element={<PrivateRoute element={<OnlineReport />} isAuthenticated={isAuthenticated} />} />
              <Route path="/quantify" element={<PrivateRoute element={<UnderConstruction pageName={'Quantify'} />} isAuthenticated={isAuthenticated} />} />
              <Route path="/aggregate" element={<PrivateRoute element={<UnderConstruction pageName={'Aggregate'} />} isAuthenticated={isAuthenticated} />} />
              <Route path="/properties" element={<PrivateRoute element={<PropertiesArchive setIsAuthenticated={setIsAuthenticated} />} isAuthenticated={isAuthenticated} />} />
              <Route path="/user" element={<PrivateRoute element={<UserProfile setIsAuthenticated={setIsAuthenticated} />} isAuthenticated={isAuthenticated} />} />
              <Route path="/settings" element={<PrivateRoute element={<UnderConstruction pageName={'Settings'} />} isAuthenticated={isAuthenticated} />} />
              <Route path="/contact" element={<PrivateRoute element={<UnderConstruction pageName={'Contacts'} />} isAuthenticated={isAuthenticated} />} />
              {/* Public routes */}
              <Route path="/login" element={<Login isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
              <Route path="/change-password" element={<ChangePassword isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
              <Route path="/forgot-password" element={<ForgotPassword isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
              <Route path="/reset-password/:uidb64/:token" element={<ResetPassword isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
              {/* Default route */}
              <Route path="/" element={<Navigate to={isAuthenticated ? "/prevent" : "/login"} />} />
              {/* Catch-all route */}
              <Route path="*" element={<Navigate to={isAuthenticated ? "/prevent" : "/login"} />} />
            </Routes>
          </Box>
        </Box>
      )}
    </BrowserRouter>
  );
}

export default App;