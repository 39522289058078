import React from "react";
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Divider, useMediaQuery, useTheme } from "@mui/material";

const DeleteConfirmation = ({ open, onClose, onConfirm }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    return (
        <Dialog 
        open={open} 
        onClose={onClose}            
        PaperProps={{
            sx: {
                width: isSmallerScreen ? '75%' : '30%',
                borderRadius: '10px'
            }
        }}>
            <DialogTitle sx={{backgroundColor:'#F8F8F8', fontSize: '16px', fontWeight: 500}}>{t('adaptions_page.delete_measure')}?</DialogTitle>
            <DialogContent sx={{ padding: 0 }}>
                <Typography variant="h6" sx={{ color: '#A8ADB4', my: 2, mx: 3}}>{t('adaptions_page.can_not_be_undone')}.</Typography>
                <Divider sx={{ color: '#A8ADB4', width:'100%', mb: 2 }}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" variant="outlined">
                {t('adaptions_page.cancel')}
                </Button>
                <Button onClick={onConfirm} color="primary" variant="contained">
                {t('adaptions_page.delete')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmation;
