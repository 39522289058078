import React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { InputLabel, useMediaQuery, useTheme } from '@mui/material';

const PersonalInfo = () => {
    const { t } = useTranslation();
    const userData = JSON.parse(localStorage.getItem('user_data'));
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));

    function toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
        );
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                <Box>
                    <Typography variant="h2" sx={{ mt: 2, mb: 2 }}>{t('user_page.personal_info')}</Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isSmallerScreen ? 'column' : 'row',
                    justifyContent: 'space-between',
                    mb: 2,
                }}
            >
                <Box sx={{ width: isSmallerScreen ? '100%' : '48%', mb: isSmallerScreen ? 2 : 0 }}>
                    <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F' }}>{t('login.first_name')}</InputLabel>
                    <TextField
                        margin="none"
                        color="primary"
                        fullWidth
                        id="first_name"
                        name="first_name"
                        value={userData.first_name}
                        disabled
                    />
                </Box>
                <Box sx={{ width: isSmallerScreen ? '100%' : '48%' }}>
                    <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F' }}>{t('login.last_name')}</InputLabel>
                    <TextField
                        margin="none"
                        color="primary"
                        fullWidth
                        id="last_name"
                        name="last_name"
                        value={userData.last_name}
                        disabled
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isSmallerScreen ? 'column' : 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Box sx={{ width: isSmallerScreen ? '100%' : '48%', mb: isSmallerScreen ? 2 : 0 }}>
                    <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F' }}>{t('login.email')}</InputLabel>
                    <TextField
                        margin="none"
                        color="primary"
                        fullWidth
                        id="email"
                        name="email"
                        value={userData.user_name}
                        disabled
                    />
                </Box>
                <Box sx={{ width: isSmallerScreen ? '100%' : '48%',  mb: isSmallerScreen ? 2 : 0 }}>
                    <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F' }}>{t('login.organization')}</InputLabel>
                    <TextField
                        margin="none"
                        color="primary"
                        fullWidth
                        id="organization"
                        name="organization"
                        value={toTitleCase(userData.organization)}
                        disabled
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default PersonalInfo;
