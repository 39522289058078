import React, { useState, useEffect } from 'react';
import { useSearchContext } from '../../../SearchContext';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Table,
    TableBody,
    Popper,
    Paper,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import GetPDF from '../report/getPDF';
import AdaptionsFilters from './adaptionsFilters';
import Datasheet from './datasheet';
import { getRiskColor, getRiskTextColor } from '../formattingUtils';
import AdaptionTableRow from './adaptionTableRow';
import AdaptionTableHead from './adaptionTableHead';
import AdaptionTableTabs from './adaptionTableTabs';
import SelectMeasures from '../existingMeasures/selectMeasures';

const AdaptionsTable = ({ riskSolutions, riskLevels, viewReport, setViewReport, setAddMeasure, datasheetsData, existingMeasures, selectedMeasures, setSelectedMeasures, isModalOpen, setIsModalOpen, setActiveTab }) => {
    const { risksToFilter } = useSearchContext();
    const { t } = useTranslation();
    const [expandedRows, setExpandedRows] = useState({});
    const [expandedRow, setExpandedRow] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [sortingCriteria, setSortingCriteria] = useState('effectiveness');
    const [anchorEl, setAnchorEl] = useState(null);
    const [popperContent, setPopperContent] = useState('');
    const [openDatasheet, setOpenDatasheet] = useState(false);
    const [selectedSolution, setSelectedSolution] = useState(null);
    const [hoveredIcon, setHoveredIcon] = useState(null);
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleAddMeasure = () => {
        setIsModalOpen(true);
        setAddMeasure(true);
        setActiveTab(1);
    }

    const handleRiskClick = (index, risks) => {
        if (risks.length > 1) {
            setExpandedRows(prev => ({
                ...prev,
                [index]: !prev[index]
            }));
        }
    };

    const handleRowClick = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    const mapEffectivenessToWords = (efficiencyCategory) => {
        let word, color;

        switch(efficiencyCategory) {
            case "low":
                word = t('effectiveness.moderate');
                color = '#B331C833';
                break;
            case "medium":
                word = t('effectiveness.effective');
                color = '#B331C880';
                break;
            case "high":
                word = t('effectiveness.super');
                color = '#B331C8';
                break;
            default:
                word = t('effectiveness.unknown');
                color = '#000000';
                break;
        }

        return (
            <Box
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    borderRadius: '3px',
                    border: '1px solid var(--Grey-Scale-Colors-Grey-Stronger, #E9EBEE)',
                    padding: '2px 6px',
                    margin: '2px',
                    whiteSpace: 'nowrap',
                    maxWidth: 'fit-content',
                }}
            >
                <Typography sx={{ color: color, display: 'inline', fontSize: '25px', position: 'relative', top: '-1px' }}>•</Typography>
                <Typography variant="riskTag" sx={{ display: 'inline' }}>{word}</Typography>
            </Box>
        );
    };


    const formatRisks = (risks, rowIndex) => {
        const isRowExpanded = expandedRows[rowIndex];

        if (!isRowExpanded && risks.length > 1) {
            return (
                <Box>
                    <Box
                        sx={{
                            display: 'inline-block',
                            borderRadius: '3px',
                            border: `1px solid ${getRiskColor(riskLevels[risks[0]])}`,
                            padding: '2px 8px',
                            margin: '2px',
                            backgroundColor: getRiskColor(riskLevels[risks[0]]) + '33',
                            maxWidth: 'fit-content',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        <Typography variant="riskTag" color={getRiskTextColor(riskLevels[risks[0]])}>
                            {t(`main_page.${risks[0]}`)}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'inline-block',
                            borderRadius: '3px',
                            border: '1px solid rgba(0, 0, 0, 0.20)',
                            background: '#EEEEEE',
                            padding: '2px 4px',
                            margin: '2px',
                            whiteSpace: 'nowrap',
                            maxWidth: 'fit-content',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleRiskClick(rowIndex, risks)}
                    >
                        <Typography variant="riskTag">
                            +{risks.length - 1}
                        </Typography>
                    </Box>
                </Box>
            );
        }
    
        return risks.map((risk, idx) => {
            const riskLevel = riskLevels[risk];
            const color = getRiskColor(riskLevel);
            const textColor = getRiskTextColor(riskLevel);
            const background = `${color}33`; // 20% opacity
            const border = `1px solid ${color}`;

            return (
                <Box
                    key={idx}
                    sx={{
                        display: 'flex',
                        borderRadius: '3px',
                        border: border,
                        background: background,
                        padding: '2px 8px',
                        margin: '2px',
                        whiteSpace: 'nowrap',
                        maxWidth: 'fit-content',
                        cursor: risks.length > 1 ? 'pointer' : 'default',
                    }}
                    onClick={() => risks.length > 1 && handleRiskClick(rowIndex, risks)}
                >
                    <Typography variant="riskTag" color={textColor}>
                        {t(`main_page.${risk}`)}
                    </Typography>
                </Box>
            );
        });
    };

    const filterSolutions = (row) => {
        return risksToFilter.length === 0 || risksToFilter.some(risk => row.risks.includes(risk));
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        switch (newValue) {
            case 0:
                setSortingCriteria('effectiveness');
                break;
            case 1:
                setSortingCriteria('affordable');
                break;
            case 2:
                setSortingCriteria('greenest');
                break;
            default:
                setSortingCriteria('effectiveness');
                break;
        }
    };

    const handleViewReport = () => {
        setViewReport(true);
    };

    const handleMouseEnter = (event, content, iconId) => {
        setAnchorEl(event.currentTarget);
        setPopperContent(content);
        setHoveredIcon(iconId);
    };

    const handleMouseLeave = () => {
        setAnchorEl(null);
        setPopperContent('');
        setHoveredIcon(null)
    };

    const handleOpenDatasheet = (solution) => {
        setOpenDatasheet(true);
        setSelectedSolution(solution);
    };

    const handleCloseDatasheet = () => {
        setOpenDatasheet(false);
        setSelectedSolution(null);
    };

    // Map risk levels to numerical values
    const riskLevelPriority = {
        "very high": 4,
        "high": 3,
        "medium": 2,
        "low": 1
    };

    // Map efficiency categories to numerical values
    const efficiencyCategoryPriority = {
        "high": 3,
        "medium": 2,
        "low": 1
    };

    // Helper function to get the highest risk level for a solution
    const getHighestRiskLevel = (solutionRisks, riskLevels) => {
        let highestRiskValue = 0;
        solutionRisks.forEach(risk => {
            const riskValue = riskLevelPriority[riskLevels[risk] || "low"];
            if (riskValue > highestRiskValue) {
                highestRiskValue = riskValue;
            }
        });
        return highestRiskValue;
    };

    // Modified sorting logic
    const sortSolutions = (solutions, criteria, riskLevels) => {
        switch (criteria) {
            case 'effectiveness':
                return solutions.sort((a, b) => {
                    // Compare efficiency category first
                    const aEfficiencyPriority = efficiencyCategoryPriority[a.efficiency_category] || 0;
                    const bEfficiencyPriority = efficiencyCategoryPriority[b.efficiency_category] || 0;

                    if (aEfficiencyPriority !== bEfficiencyPriority) {
                        return bEfficiencyPriority - aEfficiencyPriority; // Higher efficiency category first
                    }

                    // Get highest risk levels for each solution
                    const aRiskPriority = getHighestRiskLevel(a.risks, riskLevels);
                    const bRiskPriority = getHighestRiskLevel(b.risks, riskLevels);

                    if (aRiskPriority !== bRiskPriority) {
                        return bRiskPriority - aRiskPriority; // Higher risk level first
                    }

                    // Finally, compare efficiency score
                    return b.efficiency - a.efficiency; // Higher efficiency first
                });
            case 'affordable':
                return solutions.sort((a, b) => a.solution_cost - b.solution_cost);
            case 'greenest':
                return solutions.sort((a, b) => b.cobenefits - a.cobenefits);
            default:
                return solutions;
        }
    };

    // Process and sort risk solutions
    const processedRiskSolutions = sortSolutions(
        Object.keys(riskSolutions).map(key => {
            const { cost_range, efficiency, efficiency_category, cobenefits, accuracy, size, unit_r, currency, risks, solution_cost } = riskSolutions[key];
            return {
                solution: key,
                cost_range,
                efficiency,
                efficiency_category,
                cobenefits,
                accuracy,
                size,
                unit_r,
                currency,
                risks,
                solution_cost
            };
        }),
        sortingCriteria,
        riskLevels
    );

        // Getting unique risks for filtering purposes
        const getUniqueRisks = (riskSolutions) => {
            const uniqueRisks = new Set();
            Object.keys(riskSolutions).forEach(key => {
                riskSolutions[key].risks.forEach(risk => uniqueRisks.add(risk));
            });
            return Array.from(uniqueRisks);
        };
        const uniqueRisks = getUniqueRisks(processedRiskSolutions);

    return (
        <Box sx={{ border: '0.5px solid #E9EBEE', borderBottom: 'none', borderRadius: '5px' }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: { xs: 'column', sm: 'row' }, mx: 2, mb: 1, mt: 1}}>
                <Box>
                    <Typography variant="h3" gutterBottom>
                        {t('adaptions_page.proposed_adaptions_overview')}
                    </Typography>
                    <Typography variant="h6" sx={{ color: '#A8ADB4' }} gutterBottom>
                        {t('adaptions_page.measures_ranked_by_effectiveness')}
                    </Typography>
                </Box>
                <Box sx={{ display: 'inline-flex', flexDirection: 'row-reverse', mt: {xs: 0, md: 0.5} }}>
                    <GetPDF viewReport={viewReport} />
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            display: 'flex',
                            color: 'white',
                            padding: '10px 12px',
                            lineHeight: '15.4px',
                            height: '46px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '5px',
                            borderRadius: '3px',
                            boxShadow: 'none',
                            ':hover': {
                                boxShadow: 'none',
                                backgroundColor: '#B52C4C',
                            },
                        }}
                        onClick={handleViewReport}
                    >
                        {t('adaptions_page.view_report')}
                    </Button>
                    <Button
                        disabled
                        variant="contained"
                        color="primary"
                        sx={{
                            backgroundColor: 'transparent',
                            color: '#D3385C',
                            border: '1px solid #F8DEE2',
                            ':hover': {
                                backgroundColor: '#F8DEE2',
                            },
                            lineHeight: '15.4px',
                            display: 'flex',
                            height: '46px',
                            boxShadow: 'none',
                            borderRadius: '3px',
                            padding: '10px 12px',
                            marginRight: 1,
                        }}
                        onClick={handleAddMeasure}
                    >
                        {t('adaptions_page.report_measure')}
                    </Button>
                </Box>
            </Box>
            <Box sx={{ borderBottom: 0.5, borderTop: 0.5, borderColor: 'divider', display: 'flex', flexDirection: isSmallerScreen ? 'column' : 'row', justifyContent: 'space-between', alignItems: isSmallerScreen ? 'flex-start' : 'center', py: 0.5 }}>
                <AdaptionTableTabs t={t} tabValue={tabValue} handleTabChange={handleTabChange} />
                <Box sx={{ my: 1, ml: isSmallerScreen ? 1 : 0 }}>
                    <AdaptionsFilters uniqueRisks={uniqueRisks} />
                </Box>
            </Box>
            <Box sx={{ maxHeight: '62vh', overflowY: 'auto' }}>
                <Table>
                    <AdaptionTableHead
                        t={t}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={handleMouseLeave}
                        currency={processedRiskSolutions.length > 0 ? `(${processedRiskSolutions[0].currency})` : ''}
                        hoveredIcon={hoveredIcon}
                    />
                    <TableBody>
                        {processedRiskSolutions.filter(filterSolutions).map((row, index) => (
                            <AdaptionTableRow
                                key={index}
                                row={row}
                                index={index}
                                expandedRow={expandedRow}
                                handleRowClick={handleRowClick}
                                handleOpenDatasheet={handleOpenDatasheet}
                                mapEffectivenessToWords={mapEffectivenessToWords}
                                formatRisks={(risks) => formatRisks(risks, index)}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Box>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="top" disablePortal>
                <Paper sx={{ padding: 1, maxWidth: 175, wordWrap: 'break-word', boxShadow: '0px 0px 23px 0px rgba(0, 0, 0, 0.10)', lineHeight: '13.2px' }}>
                    <Typography variant='poppers'>{popperContent}</Typography>
                </Paper>
            </Popper>
            {selectedSolution && <Datasheet solution={selectedSolution} open={openDatasheet} handleCloseDialog={handleCloseDatasheet} datasheetsData={datasheetsData} />}
            {isModalOpen && <SelectMeasures isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} existingMeasures={existingMeasures} riskSolutions={riskSolutions} selectedMeasures={selectedMeasures} setSelectedMeasures={setSelectedMeasures} setAddMeasure={setAddMeasure}/>}
        </Box>
    );
};

AdaptionsTable.propTypes = {
    riskSolutions: PropTypes.object,
    riskLevels: PropTypes.object,
    viewReport: PropTypes.bool,
    setViewReport: PropTypes.func
};

export default AdaptionsTable;