import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';

const AdaptionTableTabs = ({ t, tabValue, handleTabChange }) => {

    return (
        <Box>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="adaption tabs"
                sx={{
                    backgroundColor: '#F2F2F2',
                    borderRadius: '5px',
                    width: 'fit-content',
                    padding: 0,
                    margin: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& .MuiTab-root': {
                        backgroundColor: '#F2F2F2',
                        px: {xs: 0, sm: 1},
                        py: {xs: 0, sm: 0.5},
                        mx: {xs: 0.5, sm: 1},
                        color: 'black',
                        fontSize: '12px',
                        fontWeight: 500,
                        borderRadius: '5px',
                        minHeight: 'unset',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.30)', // Add hover effect here
                        },
                    },
                    '& .MuiTab-root.Mui-selected': {
                        backgroundColor: 'white',
                        color: 'black',
                        my: 0.5,
                        mx: {xs: 0.5, sm: 1},
                        px: {xs: 0, sm: 1},
                        py: 1,
                        minHeight: 'unset',
                    },
                    '& .MuiTabs-indicator': { display: 'none' },
                }}
            >
                <Tab label={t('adaptions_page.most_effective')} />
                <Tab label={t('adaptions_page.most_affordable')} />
                <Tab label={t('adaptions_page.greenest')} />
            </Tabs>
        </Box>
    );
}

export default AdaptionTableTabs;