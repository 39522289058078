import React, { useState, useEffect } from "react";
import { Map, GoogleApiWrapper, Polygon, Marker, Polyline } from 'google-maps-react';
import { useSearchContext } from '../../SearchContext';
import { useNavigate } from "react-router-dom";
import selected_icon from "../../images/map_markers/selected_icon.svg";
import not_selected_icon from "../../images/map_markers/not_selected_icon.svg";
import clicked_building_marker from "../../images/map_markers/clicked_building_marker.svg";
import SearchBox from './searchBox';
import MapLegend from "./mapLegend";
import ReturnPeriodFilter from "./returnPeriodFilter";
import { useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import { ZoomControl, FullscreenControl, MapTypeControl } from "./customMapControls";
import { ReactComponent as LoaderButton } from '../../images/logos/logo.svg';
import { Box } from '@mui/material';

const GoogleMap = ({ google, setIsAuthenticated }) => {
  const { experiencedSewageBackup,isGroundFloor,setSelectedIndex, setIsLoading, setSearchLong, setSearchLat, propertyData, setPropertyData, allBuilding, setAllBuilding, selectedBuilding, setSelectedBuilding, setSearchByAddress, returnPeriod, riskYear, radius, setBlueSpotsData, setStreamsData, hasBasement} = useSearchContext();
  const [mapKey, setMapKey] = useState(0);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [exteriorPoints, setExteriorPoints] = useState([])
  const [interiorPoints, setInteriorPoints] = useState([])
  const [selectedMarker, setSelectedMarker] = useState([])
  const [streams, setStreams] = useState([])
  const [mapType, setMapType] = useState('roadmap')
  const isSmallerScreen = useMediaQuery(useTheme().breakpoints.down('md'));
  const [mapInstance, setMapInstance] = useState(null);
  const [mapTypeText, setMapTypeText] = useState('Satellite');

  useEffect(() => {
    // set the map key to a new value to force the map to re-render with new data.
    setMapKey(mapKey + 1);
    setSelectedIndex('all_adaptations')
    setSelectedMarker([])
    getPlotBuildings()
    getBlueSpots()
    getStreams()
  }, [propertyData]);

  const getPlotBuildings = async () => {
    if (propertyData.plot_id) {
      const plotBuildingsResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}building-info/same-plot/${propertyData.plot_id}/${returnPeriod}/${riskYear}/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
    const plotBuildings = plotBuildingsResponse.data;
    setAllBuilding(plotBuildings.buildings);
  }
  }

  const getBlueSpots = async () => {
    if (propertyData.lat && propertyData.lon) {
      const blueSpotsData = await axios.get(`${process.env.REACT_APP_API_BASE_URL}bluespots/coordinates/${propertyData.lat},${propertyData.lon}/${radius}/${returnPeriod}/${riskYear}/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
    const blueSpots = blueSpotsData.data;
    setBlueSpotsData(blueSpots)
    setExteriorPoints(blueSpots.geom_exterior.map(polygon => (
      polygon.coordinates[0].map(([lat, lng]) => ({ lat, lng }))
    )))
    setInteriorPoints(blueSpots.geom_interior.flatMap(multi_polygons => (
      multi_polygons.coordinates.map((polygon) => (
        polygon[0].map(([lat, lng]) => ({ lat, lng }))
      )
    ))))
  }
  }

  const getStreams = async () => {
    if (propertyData.lat && propertyData.lon) {
      const streamsData = await axios.get(`${process.env.REACT_APP_API_BASE_URL}streams/coordinates/${propertyData.lat},${propertyData.lon}/${radius}/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
    const streams = streamsData.data;
    setStreams(streams.geom)
    setStreamsData(streams)
  }
  }

  const OnClickHandle = (building) => {
    if (building.building_id != selectedBuilding) {
      setIsLoading(true)
      fetch(`${process.env.REACT_APP_API_BASE_URL}building-info/coordinates/filtered/${building.lat},${building.lon}/${returnPeriod}/${riskYear}/?has_basement=${!hasBasement.no}`,{ 
          method: 'GET', 
          headers:{
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}` 
              
          }})
          .then((response) => response.json())
          .then((data) => {
              setPropertyData(data)
              setSelectedBuilding(data.building_id)
              setIsLoading(false)
              navigate(`/prevent/adaptions/${data.building_id}?risk_year=${riskYear}&return_period=${returnPeriod}&has_basement=${Object.keys(hasBasement).find(key => hasBasement[key])}&is_ground_floor=${Object.keys(isGroundFloor).find(key => isGroundFloor[key])}&experienced_sewage_backup=${Object.keys(experiencedSewageBackup).find(key => experiencedSewageBackup[key])}`);
          })
          .catch((error) => {
              setIsLoading(false) 
              console.error("Error:", error);
          });
      }
  };

  function GetMarkerColor(building) {
    if (building.building_id == selectedBuilding) { 
      return selected_icon 
    }else{ 
      return not_selected_icon 
    }
  }

  function getCoordinates(props, marker, e) {
    const lat = e.latLng.lat()
    const lon = e.latLng.lng()
    setSearchLat(lat)
    setSearchLong(lon)
    setSearchByAddress(false);
    setSelectedMarker([{ 'lat': lat, 'lon': lon }])
  }

  const googleMapStyles = require('./googleMapStyles.json')

  function mapLoaded(mapProps, map) {
    map.setOptions({
      styles: googleMapStyles
    })
  }
  
  const addMapTypeListener = (mapProps, map) => {
    map.addListener('maptypeid_changed', () => {
    setMapType(map.getMapTypeId())
    })
    map.setMapTypeId(mapType)}

    const onMapReady = (mapProps, map) => {
      setMapInstance(map);
      mapLoaded(mapProps, map);
      addMapTypeListener(mapProps, map);
    };
    
    const savedLat = localStorage.getItem('previousSearchlat');
    const savedLng = localStorage.getItem('previousSearchlng');
    const prevSearchCoords = {
      lat: savedLat,
      lng: savedLng,
    }

  return(
    // if no building is selected return inital map with a zoomed out view of Oslo
    <>
      {selectedBuilding==null ? (
        <Map
        mapId={'home-map'}
        google={google}
        zoom={prevSearchCoords.lat==null ? 12 : 17}
        initialCenter={ prevSearchCoords.lat== null ? {
          lat: 59.9139,
          lng: 10.7522,
        } : {
          lat: prevSearchCoords.lat,
          lng: prevSearchCoords.lng,
        }}
        cursor={'pointer'}
        onClick={getCoordinates}
        mapTypeControl={false}
        fullscreenControl={false}
        zoomControl={false}
        streetViewControl={false}
        onReady={onMapReady}
      >

      <SearchBox setIsAuthenticated={setIsAuthenticated}/>

      {/* custom styled map control buttons */}
      <ZoomControl map={mapInstance} isSmallerScreen={isSmallerScreen}/>
      <MapTypeControl map={mapInstance} selectedBuilding={selectedBuilding} isSmallerScreen={isSmallerScreen} mapTypeText={mapTypeText} setMapTypeText={setMapTypeText}/>
      <FullscreenControl map={mapInstance} selectedBuilding={selectedBuilding} isSmallerScreen={isSmallerScreen} />

      {selectedMarker.map((marker, index)=>(
        <Marker
        key={index}
        icon={{url:clicked_building_marker,scaledSize: { width: 42, height: 42 }}}
        position={{
          lat: marker['lat'],
          lng: marker['lon'],
        }}
      />
      ))}
      </Map>
      ) : (
        // if a building is selected return a map with the building location and resulting polygons/lines
      <Map
        mapId={'building-map'}
        key={mapKey}
        google={google}
        zoom={17}
        initialCenter={{
          lat: propertyData.lat,
          lng: propertyData.lon,
        }}
        cursor={'pointer'}
        mapTypeControl={false}
        fullscreenControl={false}
        zoomControl={false}
        streetViewControl={false}
        onReady={onMapReady}
      >

      <FullscreenControl map={mapInstance} selectedBuilding={selectedBuilding} isSmallerScreen={isSmallerScreen} />

      <MapTypeControl map={mapInstance} selectedBuilding={selectedBuilding} isSmallerScreen={isSmallerScreen} mapTypeText={mapTypeText} setMapTypeText={setMapTypeText}/>

      <ReturnPeriodFilter />

      <MapLegend />

        {allBuilding.map((building) => (
          <Marker
            key={building['building_id']}
            position={{
              lat: building['lat'],
              lng: building['lon'],
            }}
            icon={{
              url: GetMarkerColor(building),
              data_cy: `building-marker-${building['building_id']}`,
              scaledSize: { width: 35, height: 35 },
            }}
            onClick={(e) => OnClickHandle(building)}
          />
        ))}

        <Polygon
          paths={propertyData.geom_plot.coordinates[0].map(([lat, lng]) => ({ lat, lng }))}
          strokeColor="#FF0000"
          strokeOpacity={0.8}
          strokeWeight={2}
          fillColor="#FFA500"
          fillOpacity={0.35}
        />

        {selectedMarker.map((marker) => (
          <Marker
            key={marker}
            icon={{ url: clicked_building_marker, scaledSize: { width: 42, height: 42 } }}
            position={{
              lat: marker['lat'],
              lng: marker['lon'],
            }}
          />
        ))}

        {streams.map((polyline, index) => (
          <Polyline
            key={index}
            path={polyline}
            strokeColor="#0000FF"
            strokeOpacity={0.8}
            strokeWeight={1.4}
            icons={[{
              'icon': { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW, scale: 2.2 },
              'offset': '100%',
              'repeat': '100px',
            }]}
          />
        ))}

        <Polygon
          paths={exteriorPoints.concat(interiorPoints)}
          strokeColor="#0000FF"
          strokeOpacity={0.8}
          strokeWeight={0}
          fillColor="#0000FF"
          fillOpacity={0.35}
          clickable={false}
        />

      </Map>
      )}
    </>
  )

};

const LoadingContainer = () => (
  <Box
  sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '80%',
      width: '100%',
  }}
>
  <LoaderButton className="logo-loader" />
</Box>
)

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_TOKEN,
  LoadingContainer: LoadingContainer
})(GoogleMap);