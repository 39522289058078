import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import GoogleMap from '../map/googleMap';
import AdaptionsTableOverview from './adaptionTable/adaptionsTableOverview';
import OnlineReport from './report/onlineReport';
import { useSearchContext } from '../../SearchContext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import RiskLevels from './riskLevels';
import PropertyAddressPaper from './propertyAddressPaper';
import { ReactComponent as LoaderButton } from '../../images/logos/logo.svg';
import '../../css/loaders.css'
import useTokenValidation from '../../useTokenValidation';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import EditMeasure from './existingMeasures/editMeasure';
import AddMeasure from './existingMeasures/addMeasure';

export default function Adaptions({ setIsAuthenticated }) {
    const { hasBasement, isGroundFloor, experiencedSewageBackup,setIsGroundFloor, setExperiencedSewageBackup, setPropertyData, setRiskYear, setHasBasement, setReturnPeriod, setSelectedBuilding, isLoading, setIsLoading, propertyData, selectedBuilding, setRisksToFilter, returnPeriod, riskYear, blueSpotData, streamsData } = useSearchContext();
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [viewReport, setViewReport] = useState(false);
    const [staticMap, setStaticMap] = useState("");
    const [staticMapLoading, setStaticMapLoading] = useState(false);
    const token = localStorage.getItem('token');
    const [existingMeasures, setExistingMeasures] = useState({});
    const [selectedMeasures, setSelectedMeasures] = useState([]);
    // parameters from the URL. These are used 
    // when the user navigates to the page directly from the URL.
    // Otherwise, the parameters are set in the search context.
    const { building_id } = useParams();
    const [searchParams] = useSearchParams();
    const risk_year = searchParams.get('risk_year');
    const return_period = searchParams.get('return_period');
    const has_basement = searchParams.get('has_basement');
    const is_ground_floor = searchParams.get('is_ground_floor');
    const experienced_sewage_backup = searchParams.get('experienced_sewage_backup');
    const [editMeasure, setEditMeasure] = useState(false);
    const [measureToEdit, setMeasureToEdit] = useState("");
    const [addMeasure, setAddMeasure] = useState(false);
    const [newMeasures, setNewMeasures] = useState([]);
    const [datasheetsData, setDatasheetsData] = useState({});

    useTokenValidation(setIsAuthenticated);


    const fetchDatasheet = () => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}info/data-sheets/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (response.status === 200) {
                setDatasheetsData(response.data);
            } else {
                console.error('Failed to fetch datasheets data:', response.status);
            }
        })
        .catch(error => {
            console.error('Error fetching datasheets data:', error);
        });
    };

    useEffect(() => {
        fetchDatasheet();
    }, [building_id]);

    useEffect(() => {
        if ((!propertyData || !selectedBuilding) || (selectedBuilding !== building_id)) {
            hasBasement[has_basement] = true;
            isGroundFloor[is_ground_floor] = true;
            experiencedSewageBackup[experienced_sewage_backup] = true;
            setRiskYear(risk_year);
            setReturnPeriod(return_period);
            setHasBasement({ ...hasBasement });
            setIsGroundFloor({ ...isGroundFloor });
            setExperiencedSewageBackup({ ...experiencedSewageBackup });
            setSelectedBuilding(building_id);
            getPropertyData();
            getExistingMeasures();
        }
    }
        , [building_id]);

    useEffect(() => {
        if (propertyData.solutions) {
        getExistingMeasures();
        setRisksToFilter([]);
        setStaticMap("");
        getStaticMap();
        }
        setViewReport(false);
    }
        , [propertyData, selectedBuilding, returnPeriod, riskYear]);

    const getPropertyData =  async () => {
        try {
            await axios.get(`${process.env.REACT_APP_API_BASE_URL}building-info/building-id/filtered/${building_id}/${return_period}/${risk_year}/?has_basement=${!hasBasement.no}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                setPropertyData(response.data);
            })}
        catch (error) {
            console.log(error);
        }
        }

    const getExistingMeasures =  async () => {
        try {
            await axios.get(`${process.env.REACT_APP_API_BASE_URL}user/existing-measures/?building_id=${building_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                setExistingMeasures(response.data);
            })}
        catch (error) {
            console.log(error);
        }
        }

    const getStaticMap = async () => {
        setStaticMapLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}static-map/building-id/${propertyData.building_id}/?return_period=${returnPeriod}&year=${riskYear}`,
                {
                    "building_info": propertyData,
                    "blue_spot": blueSpotData,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            const staticMapURL = response.data.url;
            setStaticMap(staticMapURL);
            setStaticMapLoading(false);
        }
        catch (error) {
            setStaticMapLoading(false);
            console.log(error);
        }
    }

    const handleAdaptionClick = () => {
        setViewReport(false);
    };


    return (
        <Box
            component="main"
            sx={{
                backgroundColor: '#FFFFFF',
                flexGrow: 1,
                mt: 7,
            }}
        >
                <Box
                    sx={{
                        mt: 1,
                        mb: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Breadcrumbs sx={{ ml: 3 }} separator="›">
                        <Link underline="hover" fontSize="12px" color="inherit" href="/prevent">
                            <HomeIcon
                                sx={{ verticalAlign: 'middle', mr: 1 }}
                                fontSize="inherit"
                                color="primary"
                            />
                            <Typography variant="breadcrumbs">{t('main_page.home')}</Typography>
                        </Link>
                        <Link underline="hover" color="inherit" href="/prevent">
                            <Typography variant="breadcrumbs">{t('main_page.prevent_map')}</Typography>
                        </Link>
                        {viewReport ? (
                            <Link
                                underline="hover"
                                color="inherit"
                                onClick={handleAdaptionClick}
                                style={{ cursor: 'pointer' }}
                            >
                                <Typography variant="breadcrumbs">{t('adaptions_page.adaptions')}</Typography>
                            </Link>
                        ) : (
                            <Typography variant="breadcrumbs" color='#39393D' fontWeight={500}>{t('adaptions_page.adaptions')}</Typography>
                        )}
                        {viewReport && (
                            <Typography variant="breadcrumbs" color='#39393D' fontWeight={500}>
                                {t('adaptions_page.online_report')}
                            </Typography>
                        )}
                    </Breadcrumbs>
                    {!isSmallerScreen && (
                    <Box sx={{ mr: 3 }}>
                        <Typography variant="breadcrumbs">{t('adaptions_page.where_info_coming_from')}</Typography>
                        <Link fontSize="10px" color="primary" href="/prevent">
                            {t('adaptions_page.learn_more')}
                        </Link>
                    </Box>
                    )}
                </Box>
            {isLoading && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '80vh',
                        width: '100%',
                    }}
                >
                    <LoaderButton className="logo-loader" />
                </Box>
            )}
            <Box sx={{ display: isLoading ? 'none' : 'block', height: 'calc(100vh - 95px)', maxWidth: '100%' }}>
                <Container maxWidth="100%" sx={{ height: '100%', maxWidth: '100%', pr: 0, pl: {xs: 2, sm: 4, md: 3} }}>
                    <Grid container spacing={2} sx={{ height: '100%', maxWidth: '100%' }}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={editMeasure || addMeasure ? 3 : 4}
                            lg={editMeasure || addMeasure ? 2.5 : 3.5}
                            sx={{
                                position: 'relative',
                                display: isSmallerScreen && (editMeasure || addMeasure) ? 'none' : 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                maxWidth: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexGrow: 1,
                                    maxWidth: '100%',
                                }}
                            >
                                <PropertyAddressPaper />
                                {propertyData.risk_levels && <RiskLevels riskLevels={propertyData.risk_levels} />}
                                {propertyData.geom_plot && (
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            mt: 2,
                                            position: 'relative',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <GoogleMap />
                                    </Box>
                                )}
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={editMeasure || addMeasure ? 6 : 8}
                            lg={editMeasure || addMeasure ? 7 : 8.5}
                            sx={{
                                display: isSmallerScreen && (editMeasure || addMeasure) ? 'none' : 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            <Paper
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexGrow: 1,
                                }}
                            >
                                {viewReport && (
                                    <OnlineReport
                                        riskSolutions={propertyData.solutions}
                                        riskLevels={propertyData.risk_levels}
                                        staticMap={staticMap}
                                        staticMapLoading={staticMapLoading}
                                        precipitation={propertyData.precipitation_mm_24h}
                                        runoffVolume={propertyData.runoff_volume_m3 * 1000}
                                        viewReport={viewReport}
                                    />
                                )}
                                {!viewReport &&
                                    propertyData.solutions &&
                                    propertyData.risk_levels &&
                                    existingMeasures && (
                                        <AdaptionsTableOverview
                                            buildingId={propertyData.building_id}
                                            riskSolutions={propertyData.solutions}
                                            riskLevels={propertyData.risk_levels}
                                            viewReport={viewReport}
                                            setViewReport={setViewReport}
                                            existingMeasures={existingMeasures}
                                            setExistingMeasures={setExistingMeasures}
                                            setAddMeasure={setAddMeasure}
                                            setEditMeasure={setEditMeasure}
                                            setMeasureToEdit={setMeasureToEdit}
                                            datasheetsData={datasheetsData}
                                            selectedMeasures={selectedMeasures}
                                            setSelectedMeasures={setSelectedMeasures}
                                            newMeasures={newMeasures}
                                            setNewMeasures={setNewMeasures}
                                            addMeasure={addMeasure}
                                            editMeasure={editMeasure}
                                        />
                                    )}
                            </Paper>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={3}
                            lg={2.5}
                            sx={{
                                display: (editMeasure || addMeasure) ? 'flex' : 'none',
                                flexDirection: 'column',
                                height: isSmallerScreen ? '100%' : '90vh',
                            }}
                        >
                            <Paper
                                sx={{
                                    mt: isSmallerScreen ? 0 : 6.5,
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 'calc(100vh - 95px)',
                                    overflow: 'scroll',
                                    border: '0.5px solid #E9EBEE',
                                    backgroundColor: '#F8F6F4',
                                }}
                            >
                                {editMeasure && (
                                    <EditMeasure
                                        buildingId={propertyData.building_id}
                                        riskSolutions={propertyData.solutions}
                                        setExistingMeasures={setExistingMeasures}
                                        setEditMeasure={setEditMeasure}
                                        measureToEdit={measureToEdit}
                                    />
                                )}
                                {addMeasure &&
                                    selectedMeasures.map((measure, index) => (
                                        <AddMeasure
                                            key={index}
                                            buildingId={propertyData.building_id}
                                            riskSolutions={propertyData.solutions}
                                            existingMeasures={existingMeasures}
                                            setExistingMeasures={setExistingMeasures}
                                            setAddMeasure={setAddMeasure}
                                            selectedMeasure={measure}
                                            selectedMeasures={selectedMeasures}
                                            setSelectedMeasures={setSelectedMeasures}
                                            newMeasures={newMeasures}
                                            setNewMeasures={setNewMeasures}
                                        />
                                    ))}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}
