import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, IconButton, Collapse, Box } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteIcon from '../../../images/icons/delete_icon.svg';
import EditIcon from '../../../images/icons/edit_icon.svg';
import SuccessfulTick from '../../../images/icons/successful_tick_icon.svg';
import DeleteConfirmation from './deleteConfirmation';  // Import DeleteConfirmation
import { getByProfessionalText } from '../formattingUtils';

const ExistingMeasuresTable = ({ existingMeasures, setExistingMeasures, buildingId, setEditMeasure, setMeasureToEdit, riskSolutions, newMeasures }) => {
    const { t } = useTranslation();
    const token = localStorage.getItem("token");
    const [expandedMeasure, setExpandedMeasure] = useState(null);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [measureToDelete, setMeasureToDelete] = useState(null);

    const getExistingMeasures = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}user/existing-measures/?building_id=${buildingId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            setExistingMeasures(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const editMeasure = (measureName) => {
        setEditMeasure(true);
        setMeasureToEdit(measureName);
    };

    const handleOpenDeleteDialog = (measureName) => {
        setMeasureToDelete(measureName);
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setMeasureToDelete(null);
    };

    const handleConfirmDelete = async () => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}user/existing-measures/?building_id=${buildingId}&measure_name=${measureToDelete}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (response.status === 200) {
                getExistingMeasures();
            }
            handleCloseDeleteDialog();
        } catch (error) {
            console.log(error);
            handleCloseDeleteDialog();
        }
    };

    const handleExpandClick = (measure) => {
        setExpandedMeasure(expandedMeasure === measure ? null : measure);
    };

    return (
        <Box sx={{ maxHeight: '71vh', overflowY: 'auto' }}>
            <Table>
                <TableHead sx={{ whiteSpace: 'nowrap' }}>
                    <TableRow sx={{ backgroundColor: '#F9FAFB' }}>
                        <TableCell sx={{ paddingRight: '0px', marginRight: 0 }}>{t('adaptions_page.name_of_measure')}</TableCell>
                        <TableCell>{t('property_form.size')}</TableCell>
                        <TableCell>{t('property_form.cost')}</TableCell>
                        <TableCell>{t('adaptions_page.installation_date')}</TableCell>
                        <TableCell>{t('adaptions_page.last_maintenance')}</TableCell>
                        <TableCell>{t('adaptions_page.installed_by_professional')}</TableCell>
                        <TableCell /> 
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {existingMeasures.existing_measures.length === 0 ? (
                        <TableRow sx={{ '& > *': { borderBottom: 0 }, border: 0 }}>
                            <TableCell colSpan={8}>
                                {t('adaptions_page.no_existing_measures')}
                            </TableCell>
                        </TableRow>
                    ) : (
                        existingMeasures.existing_measures.map((measure, index) => (
                            <React.Fragment key={index}>
                                <TableRow key={index} sx={{ '& > *': { borderBottom: 0, verticalAlign: 'top' }, border: 0 }}>
                                    <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: 0, borderBottom: 0 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                            <IconButton sx={{ ml: -1.5, mr: 1, color: '#A8ADB4', alignSelf: 'flex-start', padding: 0 }} onClick={() => handleExpandClick(measure.measure_name)}>
                                                {expandedMeasure === measure.measure_name ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                            </IconButton>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="solutionTitle">
                                                    {t(`solution.${measure.measure_name}.name`)}
                                                </Typography>
                                                {newMeasures.includes(measure.measure_name) && (
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', mt: 0.5 }}>
                                                        <Typography variant="addressPaperChecks" sx={{ color: '#0F914B' }}>
                                                            {t('adaptions_page.added_correctly')}
                                                        </Typography>
                                                        <img src={SuccessfulTick} alt="successful-tick" style={{ width: 20, height: 20 }} />
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={{ paddingRight: 0, borderBottom: 0 }}>{measure.measure_size ? `${measure.measure_size} ${t(`solution.${measure.measure_name}.unit`)}` : measure.measure_size}</TableCell>
                                    <TableCell sx={{ paddingRight: 0, borderBottom: 0 }}>{measure.measure_cost ? (<>{measure.measure_cost}<br />{riskSolutions[Object.keys(riskSolutions)[0]].currency}</>) : (measure.measure_cost)}</TableCell>
                                    <TableCell sx={{ paddingRight: 0, borderBottom: 0 }}>{measure.installation_date}</TableCell>
                                    <TableCell sx={{ paddingRight: 0, borderBottom: 0 }}>{measure.maintenance_date}</TableCell>
                                    <TableCell sx={{ paddingRight: 0, borderBottom: 0 }}>{getByProfessionalText(measure.by_professional, t)}</TableCell>
                                    <TableCell sx={{ paddingRight: 0, paddingLeft: 0, borderBottom: 0 }}>
                                        <img src={EditIcon} alt="edit-icon" style={{ width: 20, height: 20, cursor: 'pointer' }} onClick={() => editMeasure(measure)} />
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: 0 }}>
                                        <img src={DeleteIcon} alt="delete-icon" style={{ width: 20, height: 20, cursor: 'pointer' }} onClick={() => handleOpenDeleteDialog(measure.measure_name)} />
                                    </TableCell>
                                </TableRow>

                                {/* Expandable Content */}
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: '0.5px solid #E9EBEE' }} colSpan={8}>
                                        <Collapse in={expandedMeasure === measure.measure_name} timeout="auto" unmountOnExit>
                                            <Box sx={{ padding: 2 }}>
                                                <Typography>{t(`solution.${measure.measure_name}.long_description`)}</Typography>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))
                    )}
                </TableBody>
            </Table>

            {/* Delete Confirmation Dialog */}
            <DeleteConfirmation 
                open={isDeleteDialogOpen} 
                onClose={handleCloseDeleteDialog} 
                onConfirm={handleConfirmDelete} 
            />
        </Box>
    );
};

export default ExistingMeasuresTable;
