import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, InputAdornment, Avatar, FormControlLabel, FormHelperText, Typography, TextField, InputLabel, useMediaQuery, useTheme, IconButton } from '@mui/material'
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import axios from 'axios';
import { Close } from '@mui/icons-material'
import { ReactComponent as LoaderButton } from '../../../images/loaders/loader_white.svg';
import FormCalender from './formCalender';
import DeleteIcon from '../../../images/icons/delete_icon.svg';
import DeleteConfirmation from './deleteConfirmation';
import { CustomCheckbox } from '../../customStyledComponents';

const EditMeasure = ({ buildingId, riskSolutions, setExistingMeasures, setEditMeasure, measureToEdit }) => {
    const token = localStorage.getItem('token');
    const { t } = useTranslation();
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [measureSize, setMeasureSize] = useState(null);
    const [measureCost, setMeasureCost] = useState(null);
    const [installationDate, setInstallationDate] = useState(null);
    const [lastMaintenanceDate, setLastMaintenanceDate] = useState(null);
    const [installedByProfessional, setInstalledByProfessional] = useState({
        yes: false,
        no: false,
        idk: false,
    });
    const [errors, setErrors] = useState({});
    const [installationAnchorEl, setInstallationAnchorEl] = useState(null);
    const [maintenanceAnchorEl, setMaintenanceAnchorEl] = useState(null)
    const openInstallationCalendar = Boolean(installationAnchorEl);
    const openMaintenanceCalendar = Boolean(maintenanceAnchorEl);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [measureToDelete, setMeasureToDelete] = useState(null);

    const handleOpenDeleteDialog = (measureName) => {
        setMeasureToDelete(measureName);
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setMeasureToDelete(null);
    };

    const handleOpenInstallationCalendar = (event) => {
        setInstallationAnchorEl(event.currentTarget);
    };

    const handleCloseInstallationCalendar = () => {
        setInstallationAnchorEl(null);
    };

    const handleOpenMaintenanceCalendar = (event) => {
        setMaintenanceAnchorEl(event.currentTarget);
    };

    const handleCloseMaintenanceCalendar = () => {
        setMaintenanceAnchorEl(null);
    };

    const handleInstallationDateChange = (newDate) => {
        setInstallationDate(newDate);
        handleCloseInstallationCalendar();
    };

    const handleMaintenanceDateChange = (newDate) => {
        setLastMaintenanceDate(newDate);
        handleCloseMaintenanceCalendar();
    };

    // Populate form with values from measureToEdit when component mounts
    useEffect(() => {
        if (measureToEdit) {
            setMeasureSize(measureToEdit.measure_size || '');
            setMeasureCost(measureToEdit.measure_cost || '');
            setInstallationDate(measureToEdit.installation_date ? dayjs(measureToEdit.installation_date) : null);
            setLastMaintenanceDate(measureToEdit.maintenance_date ? dayjs(measureToEdit.maintenance_date) : null);
            setInstalledByProfessional({
                yes: measureToEdit.by_professional === 'yes',
                no: measureToEdit.by_professional === 'no',
                idk: measureToEdit.by_professional === 'idk',
            });
        }
    }, [measureToEdit]);

    // Validation logic for measureSize and measureCost
    const validateInputs = () => {
        const newErrors = {};
        const sizePattern = /^[0-9]+$/; // Only positive whole numbers

        // Allow null or empty values for measureSize and measureCost
        if (measureSize && !sizePattern.test(measureSize)) {
            newErrors.measureSize = t('adaptions_page.must_be_whole_number');
        }
        if (measureCost && !sizePattern.test(measureCost)) {
            newErrors.measureCost = t('adaptions_page.must_be_whole_number');
        }

        // Check if any professional installation option is selected
        if (!(installedByProfessional.yes || installedByProfessional.no || installedByProfessional.idk)) {
            newErrors.installedByProfessional = t('adaptions_page.installed_by_professional_required');
        }

        setErrors(newErrors);

        // Return whether the form has errors
        return Object.keys(newErrors).length === 0;
    };

    const getExistingMeasures =  async () => {
        try {
            await axios.get(`${process.env.REACT_APP_API_BASE_URL}user/existing-measures/?building_id=${buildingId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                setExistingMeasures(response.data);
            })}
        catch (error) {
            console.log(error);
        }
        }

    const editAMeasure = async () => {
        if (!validateInputs()) {
            return; // Do not proceed if there are validation errors
        }
        
        setIsLoading(true);
        
        // Check if measureSize or measureCost is empty and set to null accordingly
        const formattedInstallationDate = installationDate ? dayjs(installationDate).format('YYYY-MM-DD') : null;
        const formattedMaintenanceDate = lastMaintenanceDate ? dayjs(lastMaintenanceDate).format('YYYY-MM-DD') : null;
    
        const byProfessional = Object.keys(installedByProfessional).find(key => installedByProfessional[key]);

        // Set measureCost and measureSize to null if they are empty
        const formattedMeasureSize = measureSize ? measureSize : null;
        const formattedMeasureCost = measureCost ? measureCost : null;
    
        try {
            await axios.patch(`${process.env.REACT_APP_API_BASE_URL}user/existing-measures/?building_id=${buildingId}&by_professional=${byProfessional}&measure_name=${measureToEdit.measure_name}&measure_size=${formattedMeasureSize}&measure_cost=${formattedMeasureCost}&installation_date=${formattedInstallationDate}&maintenance_date=${formattedMaintenanceDate}`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            getExistingMeasures();
        } catch (error) {
            console.log(error);
        }
        
        setIsLoading(false);
        setEditMeasure(false);
    };
    
    const handleConfirmDelete = async () => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}user/existing-measures/?building_id=${buildingId}&measure_name=${measureToDelete}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (response.status === 200) {
                getExistingMeasures();
            }
            handleCloseDeleteDialog();
            setEditMeasure(false);
        } catch (error) {
            console.log(error);
            handleCloseDeleteDialog();
        }
    };

    const confirmInstalledByProfessional = (event) => {
        setInstalledByProfessional({
            ...installedByProfessional,
            [event.target.name]: event.target.checked,
        });
    };

    const getImageSrc = (measure) => {
        try {
            // Attempt to require the specific image
            return require(`../../../images/solutions/${measure}.jpg`);
        } catch (error) {
            // If the image doesn't exist, return the default "coming soon" image
            return require(`../../../images/solutions/image_coming_soon.jpg`);
        }
    };

    return(
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mb: 3, alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h3">{t('adaptions_page.edit_existing_measure')}</Typography>
                <IconButton onClick={() => setEditMeasure(false)}>
                    <Close />
                </IconButton>
            </Box>
            {/* <Typography variant="addressPaperChecks" sx={{color:'#A8ADB4'}}>placeholder text</Typography> */}

                <Box>
                    <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2, mb: 1 }}>
                        {t('adaptions_page.name_of_measure')}
                    </InputLabel>
                    <TextField
                        disabled
                        value={t(`solution.${measureToEdit.measure_name}.name`)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Avatar sx={{ width: 24, height: 24 }} src={getImageSrc(measureToEdit.measure_name)} />
                                </InputAdornment>
                            ),
                            style: {fontSize: '14px'},
                            readOnly: true
                        }}
                    />
                </Box>

                <Box sx={{ display: 'inline-flex', gap: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2, mb: 1 }}>{t('adaptions_page.measure_size')}</InputLabel>
                        <TextField
                            margin="none"
                            color="primary"
                            fullWidth
                            id="size"
                            placeholder={t('adaptions_page.enter_size')}
                            name="size"
                            value={measureSize}
                            onChange={(e) => setMeasureSize(e.target.value)}
                            error={Boolean(errors.measureSize)}
                        />
                        <FormHelperText sx={{ color: 'red' }}>{errors.measureSize}</FormHelperText>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                        <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2, mb: 1 }}>{t('pdf.table_header_units')}</InputLabel>
                        <TextField
                            disabled
                            margin="none"
                            color="primary"
                            fullWidth
                            id="size"
                            name="size"
                            value={t(`solution.${measureToEdit.measure_name}.unit`)}
                        />
                    </Box>
                </Box>

                <Box sx={{ display: 'inline-flex', gap: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2, mb: 1 }}>{t('adaptions_page.measure_cost')}</InputLabel>
                        <TextField
                            margin="none"
                            color="primary"
                            fullWidth
                            placeholder={t('adaptions_page.enter_cost')}
                            name="measureCost"
                            id="measureCost"
                            value={measureCost}
                            onChange={(e) => setMeasureCost(e.target.value)}
                            error={Boolean(errors.measureCost)}
                        />
                        <FormHelperText sx={{ color: 'red' }}>{errors.measureCost}</FormHelperText>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                        <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2, mb: 1 }}>{t('adaptions_page.currency')}</InputLabel>
                        <TextField
                            disabled
                            margin="none"
                            color="primary"
                            fullWidth
                            id="size"
                            name="size"
                            value={riskSolutions[Object.keys(riskSolutions)[0]].currency}
                        />
                    </Box>
                </Box>


            <FormCalender
                label={t('adaptions_page.installation_date')}
                dateValue={installationDate}
                onDateChange={handleInstallationDateChange}
                open={openInstallationCalendar}
                anchorEl={installationAnchorEl}
                onOpen={handleOpenInstallationCalendar}
                onClose={handleCloseInstallationCalendar}
                installationDate={installationDate}
            />

            <FormCalender
                label={t('adaptions_page.last_maintenance')}
                dateValue={lastMaintenanceDate}
                onDateChange={handleMaintenanceDateChange}
                open={openMaintenanceCalendar}
                anchorEl={maintenanceAnchorEl}
                onOpen={handleOpenMaintenanceCalendar}
                onClose={handleCloseMaintenanceCalendar}
                installationDate={installationDate}
            />

            <Box sx={{my:2}}>
            <Box sx={{display: 'inline-flex', animation: errors.installedByProfessional ? 'shake 0.5s ease-in-out' : 'none', color: errors.installedByProfessional ? 'primary.main' : 'inherit'}}>
            <Typography variant="body1" color="info" sx={{ mb:1, fontSize: isSmallerScreen ? '0.75rem' : '1rem' }}>
            {t('adaptions_page.installed_by_professional')}
            </Typography>
            {errors.installedByProfessional && <Typography color="primary" variant="body2" sx={{ ml: 1 }}>*</Typography>}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <FormControlLabel
                    label={t('main_page.yes')}
                    name='yes'
                    control={<CustomCheckbox checked={installedByProfessional.yes} onChange={confirmInstalledByProfessional} disabled={installedByProfessional.no || installedByProfessional.idk} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
                <FormControlLabel
                    label={t('main_page.no')}
                    name='no'
                    control={<CustomCheckbox checked={installedByProfessional.no} onChange={confirmInstalledByProfessional} disabled={installedByProfessional.yes || installedByProfessional.idk} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
                <FormControlLabel
                    label={t('main_page.i_don_t_know')}
                    name='idk'
                    control={<CustomCheckbox checked={installedByProfessional.idk} onChange={confirmInstalledByProfessional} disabled={installedByProfessional.yes || installedByProfessional.no} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
            </Box>
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Button
                    variant="contained"
                    sx={{padding: '5px 6px', mr: 1}}
                    onClick={() => {
                        editAMeasure();
                    }}
                >
                    {!isLoading ? t('adaptions_page.update_information') : <LoaderButton className="pdf-spinner" />}
                </Button>
                <Button
                    onClick={() => handleOpenDeleteDialog(measureToEdit.measure_name)} 
                >
                    <img src={DeleteIcon} alt='delete-icon' style={{ width: 20, height: 20, cursor: 'pointer', marginRight: 1 }}/>
                    {t('adaptions_page.delete')}
                </Button>
            </Box>

            {/* Delete Confirmation Dialog */}
            <DeleteConfirmation 
                open={isDeleteDialogOpen} 
                onClose={handleCloseDeleteDialog} 
                onConfirm={handleConfirmDelete} 
            />

        </Box>
    )
}

export default EditMeasure;