import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Button, Dialog, DialogContent, FormControl, Chip, IconButton, InputAdornment, TextField, Typography, List, ListItem, Collapse, Divider, useMediaQuery, useTheme } from "@mui/material";
import { Close, ArrowDropDown } from '@mui/icons-material';
import { CustomCheckbox } from "../../customStyledComponents";
import SearchIcon from '@mui/icons-material/Search';

const SelectMeasures = ({ isModalOpen, setIsModalOpen, existingMeasures, riskSolutions, selectedMeasures, setSelectedMeasures, setAddMeasure }) => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedMeasure, setExpandedMeasure] = useState(null);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setIsModalOpen(false);
        setAddMeasure(false);
    };

    const handleClick = () => {
        setIsModalOpen(false);
        if (selectedMeasures.length == 0) {
            setAddMeasure(false);
        }
    };

    // Handle checkbox change
    const handleToggle = (measure) => () => {
        const currentIndex = selectedMeasures.indexOf(measure);
        const newChecked = [...selectedMeasures];

        if (currentIndex === -1) {
            newChecked.push(measure); // Add to selected if not already selected
        } else {
            newChecked.splice(currentIndex, 1); // Remove if already selected
        }

        setSelectedMeasures(newChecked);
    };

    // Handle removing a chip (deselecting measure)
    const handleDelete = (measure) => () => {
        setSelectedMeasures(selectedMeasures.filter(item => item !== measure));
    };

    // Toggle the description expansion
    const handleExpandClick = (measure) => {
        setExpandedMeasure(expandedMeasure === measure ? null : measure); // Toggle measure expansion
    };

    // Filter to exclude existing measures
    const availableMeasures = Object.keys(riskSolutions).filter((measure) =>
        !existingMeasures.existing_measures.some(existing => existing.measure_name === measure)
    );

    // Filter measures based on search term
    const filteredMeasures = availableMeasures.filter(measure =>
        t(`solution.${measure}.name`).toLowerCase().includes(searchTerm.toLowerCase())
    );


    const getImageSrc = (measure) => {
        try {
            // Attempt to require the specific image
            return require(`../../../images/solutions/${measure}.jpg`);
        } catch (error) {
            // If the image doesn't exist, return the default "coming soon" image
            return require(`../../../images/solutions/image_coming_soon.jpg`);
        }
    };

    return (
        <Dialog
            open={isModalOpen}
            onClose={handleClose}
            fullScreen={isSmallScreen} // Fullscreen for sm and xs screens
            sx={{
                '& .MuiDialog-container': {
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }}
            PaperProps={{
                sx: {
                    height: isSmallScreen ? '100vh' : 'auto', // Full height for small screens
                    width: isSmallScreen ? '100vw' : '50%', // Full width for small screens
                    borderRadius: isSmallScreen ? 0 : '5px', // No border-radius for full-screen
                    margin: 0, // Remove margins for full-screen
                    display: 'flex', // Ensure flexbox for content alignment
                    flexDirection: 'column', // Align content vertically
                },
            }}
        >
            <Box sx={{ display: "flex", justifyContent: "space-between", backgroundColor: '#F8F6F4', paddingTop: 0.5, paddingBottom: 1.5 }}>
                <Typography variant="h3" sx={{marginTop: 2, marginLeft: 3}}>{t('adaptions_page.select_measures')}</Typography>
                <IconButton sx={{marginTop: 1}} onClick={handleClose}>
                    <Close />
                </IconButton>
            </Box>
            <Divider />
            <DialogContent 
                sx={{ 
                    backgroundColor: '#F8F6F4', 
                    height: '100%', // Fill the remaining height
                    display: 'flex', 
                    flexDirection: 'column', 
                    padding: '24px' 
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                    <Typography variant="h5" sx={{ lineHeight: '18px', mb: 0.5 }}>{t('adaptions_page.select_from_list')}</Typography>
                    <Typography sx={{ color: '#A8ADB4', fontSize: '14px', fontWeight: '450', lineHeight: '16px' }}>{t('adaptions_page.select_all_measures_that_apply_to_property')}</Typography>
                </Box>

                <Box>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ mb: 2 }}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                        ),
                    }}
                    />
                    {selectedMeasures.length > 0 && 
                    <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center', minHeight: '24px' }}>
                        <Typography sx={{color: '#A8ADB4', fontSize: '14px'}}>{t('adaptions_page.selections')}</Typography>
                        {selectedMeasures.map((measure) => (
                            <Chip
                                key={measure}
                                label={t(`solution.${measure}.name`)}
                                onDelete={handleDelete(measure)}
                                deleteIcon={<Close />}
                                sx={{
                                    fontSize: '10px',
                                    borderRadius: '3px',
                                    height: '24px',
                                    backgroundColor: '#4747470D',
                                    '& .MuiChip-deleteIcon': { fontSize: '12px' }
                                }}
                            />
                        ))}
                    </Box>
                    }

                    {/* Scrollable List of Measures */}
                    <FormControl fullWidth>
                        <Box sx={{ maxHeight: '40vh', overflowY: 'auto' }}>
                            <List>
                                {filteredMeasures.map((measure) => (
                                    <ListItem key={measure} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderBottom: '1px solid #E9EBEE' }}>
                                        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleToggle(measure)}>
                                                <CustomCheckbox
                                                    edge="start"
                                                    checked={selectedMeasures.indexOf(measure) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${measure}` }}
                                                />
                                                <Avatar sx={{marginRight: 1}} src={getImageSrc(measure)}/>
                                                <Typography>
                                                    {t(`solution.${measure}.name`)}
                                                </Typography>
                                            </Box>
                                            <IconButton
                                                onClick={() => handleExpandClick(measure)}
                                                sx={{
                                                    transform: expandedMeasure === measure ? 'rotate(180deg)' : 'rotate(0deg)',
                                                    transition: 'transform 0.3s',
                                                    color: expandedMeasure === measure ? 'primary.main' : '#A8ADB4',
                                                    '&:hover': { color: 'primary.main' }
                                                }}
                                            >
                                                <ArrowDropDown />
                                            </IconButton>
                                        </Box>
                                        <Collapse in={expandedMeasure === measure} timeout="auto" unmountOnExit>
                                            <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'flex-start', mx: 4, my: 1, gap: '16px' }}>
                                                <img
                                                    src={getImageSrc(measure)}
                                                    alt={t(`solution.${measure}.name`)}
                                                    style={{ width: isSmallScreen? '100%' : '200px', height: isSmallScreen ? '100%' : '200px', borderRadius: '5px' }}
                                                />
                                                <Typography sx={{ fontSize: '0.875rem', color: '#6A6C6F' }}>
                                                    {t(`solution.${measure}.long_description`)}
                                                </Typography>
                                            </Box>
                                        </Collapse>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </FormControl>
                </Box>

                <Box sx={{ mt: 2 }}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleClick}
                    >
                        {t('adaptions_page.save_info')}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
};

export default SelectMeasures;
