import React from 'react';
import { TableHead, TableRow, TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import InfoIcon from '../../../images/icons/info_icon.svg';
import InfoIconHover from '../../../images/icons/info_icon_hover.svg';

const AdaptionTableHead = ({ t, handleMouseEnter, handleMouseLeave, currency, hoveredIcon }) => {
    return (
        <TableHead sx={{ whiteSpace: 'nowrap'}}>
            <TableRow sx={{ backgroundColor: '#F9FAFB' }}>
                <TableCell sx={{ paddingRight: '0px', marginRight: 0 }}>
                    {t('main_page.ranked_solutions')}
                </TableCell>
                <TableCell>
                    {t('main_page.efficiency')}
                    <img
                        src={hoveredIcon === 'efficiency' ? InfoIconHover : InfoIcon}
                        alt="info-icon"
                        style={{ cursor: 'pointer', verticalAlign: 'middle', marginLeft: 3, width: '16px', height: '16px' }}
                        onMouseEnter={(e) => handleMouseEnter(e, t('poppers.effectivity'), 'efficiency')}
                        onMouseLeave={handleMouseLeave}
                    />
                </TableCell>
                <TableCell>
                    {t('main_page.risk_type')}
                    <img
                        src={hoveredIcon === 'risk_type' ? InfoIconHover : InfoIcon}
                        alt="info-icon"
                        style={{ cursor: 'pointer', verticalAlign: 'middle', marginLeft: 3, width: '16px', height: '16px' }}
                        onMouseEnter={(e) => handleMouseEnter(e, t('poppers.risk_type'), 'risk_type')}
                        onMouseLeave={handleMouseLeave}
                    />
                </TableCell>
                <TableCell>
                    {t('main_page.co_benefits')}
                    <img
                        src={hoveredIcon === 'co_benefits' ? InfoIconHover : InfoIcon}
                        alt="info-icon"
                        style={{ cursor: 'pointer', verticalAlign: 'middle', marginLeft: 3, width: '16px', height: '16px' }}
                        onMouseEnter={(e) => handleMouseEnter(e, t('poppers.co_benefits'), 'co_benefits')}
                        onMouseLeave={handleMouseLeave}
                    />
                </TableCell>
                <TableCell>{t('main_page.units')}</TableCell>
                <TableCell>{t('main_page.cost')} {currency}</TableCell>
                <TableCell>
                    {t('main_page.score')}
                    <img
                        src={hoveredIcon === 'score' ? InfoIconHover : InfoIcon}
                        alt="info-icon"
                        style={{ cursor: 'pointer', verticalAlign: 'middle', marginLeft: 3, width: '16px', height: '16px' }}
                        onMouseEnter={(e) => handleMouseEnter(e, t('poppers.accuracy'), 'score')}
                        onMouseLeave={handleMouseLeave}
                    />
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

AdaptionTableHead.propTypes = {
    t: PropTypes.func.isRequired,
    handleMouseEnter: PropTypes.func.isRequired,
    handleMouseLeave: PropTypes.func.isRequired,
    currency: PropTypes.string.isRequired,
    hoveredIcon: PropTypes.string,
};

export default AdaptionTableHead;
