import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdaptionsTable from './adaptionsTable';
import ExistingMeasures from '../existingMeasures/existingMeasures';
import { Box, Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Divider } from '@mui/material';

const AdaptionsTableOverview = ({
    buildingId,
    riskSolutions,
    riskLevels,
    viewReport,
    setViewReport,
    existingMeasures,
    setExistingMeasures,
    setAddMeasure,
    setEditMeasure,
    setMeasureToEdit,
    datasheetsData,
    selectedMeasures,
    setSelectedMeasures,
    newMeasures,
    setNewMeasures,
    addMeasure,
    editMeasure,
}) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [pendingTab, setPendingTab] = useState(null);

    const handleTabChange = (event, newValue) => {
        if (newValue === 0 && (addMeasure || editMeasure)) {
            setIsDialogOpen(true);
            setPendingTab(newValue);
        } else {
            setActiveTab(newValue);
            if (newValue === 0) {
                setAddMeasure(false);
                setEditMeasure(false);
            }
        }
    };

    const handleDialogClose = (confirm) => {
        setIsDialogOpen(false);
        if (confirm) {
            setAddMeasure(false);
            setEditMeasure(false);
            setActiveTab(pendingTab);
        }
    };

    return (
        <Box>
            {riskSolutions && existingMeasures.existing_measures &&
            <Box sx={{ mb: 2 }}>
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="adaptions overview tabs"
                variant="scrollable"
                sx={{
                    borderBottom: '0.5px solid #E9EBEE',
                    '& .MuiTab-root': {
                        padding: '0px 15px 15px',
                        minHeight: 'unset',
                        fontWeight: 500,
                        borderBottom: '0.5px solid transparent',
                        '&:hover': {
                            borderBottom: '0.5px solid #D3385C',
                        },
                    },
                    minHeight: 'unset',
                    height: 'auto',
                    paddingTop: 0,
                }}
            >
                <Tab label={`${t('adaptions_page.proposed_measures')} (${Object.keys(riskSolutions).length})`} />
                <Tab disabled label={`${t('adaptions_page.existing_measures')} (${existingMeasures.existing_measures.length})`} />
            </Tabs>
            </Box>
            }

            {activeTab === 0 && (
                <AdaptionsTable
                    riskSolutions={riskSolutions}
                    riskLevels={riskLevels}
                    viewReport={viewReport}
                    setViewReport={setViewReport}
                    setAddMeasure={setAddMeasure}
                    datasheetsData={datasheetsData}
                    existingMeasures={existingMeasures}
                    selectedMeasures={selectedMeasures}
                    setSelectedMeasures={setSelectedMeasures}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen} 
                    setActiveTab={setActiveTab}
                />
            )}

            {activeTab === 1 && (
                <ExistingMeasures
                    existingMeasures={existingMeasures}
                    setExistingMeasures={setExistingMeasures}
                    buildingId={buildingId}
                    setAddMeasure={setAddMeasure} 
                    setEditMeasure={setEditMeasure}
                    setMeasureToEdit={setMeasureToEdit}
                    riskSolutions={riskSolutions}
                    selectedMeasures={selectedMeasures}
                    setSelectedMeasures={setSelectedMeasures}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    newMeasures={newMeasures}
                    setNewMeasures={setNewMeasures}
                />
            )}

            <Dialog 
                open={isDialogOpen} 
                onClose={() => handleDialogClose(false)}
                PaperProps={{
                sx: {
                    width: '25%',
                    borderRadius: '10px'
                }
                }}>
                <DialogTitle sx={{backgroundColor:'#F8F8F8', fontSize: '16px', fontWeight: 500}}>{t('adaptions_page.are_you_sure')}</DialogTitle>
                <DialogContent sx={{ padding: 0 }}>
                    <Typography variant="h6" sx={{ color: '#A8ADB4', my: 2, mx: 3}}>{t('adaptions_page.unsaved_changes_lost')}.</Typography>
                    <Divider sx={{ color: '#A8ADB4', width:'100%', mb: 2 }}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose(false)} color="primary" variant="outlined">
                    {t('adaptions_page.cancel')}
                    </Button>
                    <Button onClick={() => handleDialogClose(true)} color="primary" variant="contained">
                    {t('adaptions_page.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AdaptionsTableOverview;